import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { OrdersPageComponent } from './orders-page/orders-page.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog'; 
import { MatGridListModule } from '@angular/material/grid-list';
import { FinancialComponent } from './financial/financial.component';
import { FoodItemsComponent } from './food-items/food-items.component';
import { HistoryComponent } from './history/history.component';
import { SidenavItemsComponent } from './sidenav-items/sidenav-items.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoutComponent } from './logout/logout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthInterceptor } from './auth/interceptor/auth.interceptor';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { NewOrdersComponent } from './new-orders/new-orders.component';
import { MatSidenavModule } from '@angular/material/sidenav'; 
import { MatTooltipModule } from '@angular/material/tooltip'; 
import {  BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatepickerModule } from 'ng2-datepicker/lib/datepicker.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {MatSliderModule} from '@angular/material/slider';

registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrdersPageComponent,
    SidenavItemsComponent,
    HistoryComponent,
    FoodItemsComponent,
    FinancialComponent,
    LogoutComponent,
    NewOrdersComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatGridListModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatCardModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSnackBarModule,
    CalendarModule,
    FormsModule,
    InputTextModule,
    MatSidenavModule,
    MatTooltipModule,
    BsDatepickerModule.forRoot(),
    ToastModule,
    MatSliderModule
    
   
  ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },MessageService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule { }
