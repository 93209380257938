<hr class="my-3">
<div class="d-lg-block d-none ">
  <div class="row px-3">
    <div class=" col-8 px-0 position-relative">
      <table class="history-table ">
        <thead class="py-3 ">
          <tr class="history-table-head">
            <th class="text-center history-order-columns py-3 px-1 history-head">Order ID</th>
            <th class="text-center history-amount-column py-3 px-1 history-head">Product Details</th>
            <th class="text-center history-order-column py-3 px-1 history-head">Amount</th>
            <th class="text-center history-order-columns py-3 px-1 history-head">Action</th>
           <th class="text-center history-order-columns py-3 px-1 history-head"></th>
            <!-- <div class="btn-group py-2 downarrow dropdown">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" id="sortbymenu">
                <span class=" Sort-by">Sort by</span>
              </button>
              <ul class="dropdown-menu shadow pointer p-2 pt-0" aria-labelledby="sortbymenu">

                <hr class="m-2">
                <div class="dropdown-item d-flex pointer py-2 ps-1">
                  <input type="radio" name="sort" value="complete">
                  <div class="ms-2 Sort-by">Completed</div>
                </div>
                <div class="dropdown-item d-flex pointer py-2 ps-1">
                  <input type="radio" name="sort" value="cancel">
                  <div class="ms-2 Sort-by">Cancel</div>
                </div>
                <div class="dropdown-item d-flex pointer py-2 ps-1">
                  <input type="radio" name="sort" value="reject">
                  <div class="ms-2 Sort-by">Reject</div>
                </div>
              </ul>
            </div> -->
            <!-- <th class="text-center history-action-column py-2 px-1 "> 
          <div class="btn-group" >
          <button type="button" class="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Sort by
          </button>
          <ul class="dropdown-menu shadow pointer">
              <div class="dropdown-item d-flex pointer">
                <input type="radio" name="sort" value="volvo"> 
                <div class="fw-bold ms-2">Completed</div>
              </div>
              <div class="dropdown-item d-flex pointer">
                <input type="radio" name="sort" value="saab"> 
                <div class="fw-bold ms-2">Cancel</div>
              </div>
              <div class="dropdown-item d-flex pointer">
                <input type="radio" name="sort" value="audi"> 
                <div class="fw-bold ms-2">Reject</div>
              </div>
          </ul>
        </div>
        </th> -->
          </tr>
        </thead>
        <tbody class="history-body mt-3">
          <tr class="py-1 order-row history-head-solid m-0 mb-3 " *ngFor="let order of completeData">
            <td class="text-center mt-2 product-details default history-order-column py-0 px-1 product-name mt-3 ">
              {{order.ordernumber}}</td>
            <td class="history-order-columns py-0 px-1 product-name d-flex flex-column justify-content-center align-items-center">
              <ul class="list-unstyled p-0 mb-1 product-details default text-center">
                <li>{{order.cartitems[0].producttitle}}</li>
              </ul>
              <div class="additional-order title-color text-center" *ngIf="order.cartitems.length > 1">+{{order.cartitems.length - 1}} more items</div>
            </td>
            <td class="history-status-column  text-center history-product-details default py-0 px-1 product-name mt-3 ">
              <span class="currency-symbol"></span>{{order.amount}}</td>
            <td class="text-center px-1 py-0  history-order-column mt-1 ">
              <div class="d-flex justify-content-evenly">
                <button class="btn completed px-3 py-2 d-flex" type="button" data-bs-toggle="offcanvas"  data-bs-target="#historyId" aria-controls="historyId"  (click)="completelist(order);historyoffset()">Completed </button>
              </div>
            </td>
            <td class="history-order-column text-end">
              <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon  ps-md-2 m-0 ps-1 pointer" (click)="completelist(order);historyoffset()"data-bs-toggle="offcanvas" data-bs-target="#historyId" aria-controls="historyId">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
   
    <!-- <div class="col-4 text-end  px-0 " [ngClass]="{ 'with-value': startDateValue || endDateValue }">
      <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [inline]="true"
        [maxDate]="maxDate" (onSelect)="updateDate()">
        
        <ng-template pTemplate="footer">
          <div class="input-fields">
            <input type="text" pInputText [(ngModel)]="startDateValue" placeholder="Start Date"
              class="m-3 text-center startDateValue" readonly />
            <input type="text" pInputText [(ngModel)]="endDateValue" placeholder="End Date"
              class="m-3 text-center endDateValue" readonly />
          </div>
        </ng-template>
      </p-calendar>
    </div> -->

    <!-- <div class="col-4 text-end px-0" [ngClass]="{ 'with-value': startDateValue || endDateValue }">
      <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [inline]="true"
        [maxDate]="maxDate" (onSelect)="updateDate()">
        
        <ng-template let-date pTemplate="date">
          <span [ngClass]="{
            'start-date': isStartDate(date),
            'end-date': isEndDate(date),
            'in-range': isInRange(date)
          }" class="p-ripple p-element">
            {{ date.day }}
          </span>
        </ng-template>
    
        <ng-template pTemplate="footer">
          <div class="input-fields">
            <input type="text" pInputText [(ngModel)]="startDateValue" placeholder="Start Date"
              class="m-3 text-center startDateValue" readonly />
              <span class="my-auto to"> to</span>
            <input type="text" pInputText [(ngModel)]="endDateValue" placeholder="End Date"
              class="m-3 text-center endDateValue" readonly />
          </div>
        </ng-template>
      </p-calendar>
    </div> -->
    
    <div class="col-4 text-end px-0" [ngClass]="{ 'with-value': startDateValue || endDateValue }">
      <p-calendar 
        [(ngModel)]="rangeDates" 
        selectionMode="range" 
        [readonlyInput]="true" 
        [inline]="isInline"
        [maxDate]="maxDate" 
        (onSelect)="updateDate()"
        [style]="calendarStyles">
        
        <ng-template let-date pTemplate="date">
          <span [ngClass]="{
            'start-date': isStartDate(date),
            'end-date': isEndDate(date),
            'in-range': isInRange(date)
          }" class="p-ripple p-element">
            {{ date.day }}
          </span>
        </ng-template>
    
        <ng-template pTemplate="footer">
          <div class="input-fields">
            <input 
              type="text" 
              pInputText 
              [(ngModel)]="startDateValue" 
              placeholder="Start Date"
              class="m-3 text-center startDateValue" 
              readonly 
            />
            <span class="my-auto to"> to</span>
            <input 
              type="text" 
              pInputText 
              [(ngModel)]="endDateValue" 
              placeholder="End Date"
              class="m-3 text-center endDateValue" 
              readonly 
            />
          </div>
        </ng-template>
      </p-calendar>
    </div>
    



  </div>
</div>


<!-- for mobile -->
<div class=" d-lg-none d-block">
  <!-- <div class="mobile-history  px-0 my-3" [ngClass]="{ 'with-value': startDateValue || endDateValue }">
    <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [inline]="true"
    [maxDate]="maxDate" (onSelect)="updateDate()">
      <ng-template let-date pTemplate="date">
        <span [ngClass]="{
          'start-date': isStartDate(date),
          'end-date': isEndDate(date),
          'in-range': isInRange(date)
        }" class="p-ripple p-element">
          {{ date.day }}
        </span>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="input-fields">
          <input type="text" pInputText [(ngModel)]="startDateValue" placeholder="Start Date"
            class="m-3 text-center startDateValue" readonly />
            <span class="my-auto to"> to</span>
          <input type="text" pInputText [(ngModel)]="endDateValue" placeholder="End Date"
            class="m-3 text-center endDateValue" readonly />
        </div>
      </ng-template>
    </p-calendar>
  </div> -->

  <div class="mobile-history  px-0 my-3" [ngClass]="{ 'with-value': startDateValue || endDateValue }">
    <p-calendar 
      [(ngModel)]="rangeDates" 
      selectionMode="range" 
      [readonlyInput]="true" 
      [inline]="true"
      [maxDate]="maxDate" 
      (onSelect)="updateDate()"
      [style]="calendarStyles">
      
      <ng-template let-date pTemplate="date">
        <span [ngClass]="{
          'start-date': isStartDate(date),
          'end-date': isEndDate(date),
          'in-range': isInRange(date)
        }" class="p-ripple p-element">
          {{ date.day }}
        </span>
      </ng-template>
  
      <ng-template pTemplate="footer">
        <div class="input-fields">
          <input 
            type="text" 
            pInputText 
            [(ngModel)]="startDateValue" 
            placeholder="Start Date"
            class="m-3 text-center startDateValue" 
            readonly 
          />
          <span class="my-auto to"> to</span>
          <input 
            type="text" 
            pInputText 
            [(ngModel)]="endDateValue" 
            placeholder="End Date"
            class="m-3 text-center endDateValue" 
            readonly 
          />
        </div>
      </ng-template>
    </p-calendar>
  </div>
  




  <div class=" px-0 overflow-auto position-relative">
    <table class="history-table mt-2">
      <thead class="py-3">
        <tr class="history-table-head">
          <th class="text-center history-order-columns py-3 px-1 history-head">Order ID</th>
          <th class="text-center history-order-columns py-3 px-1 history-head">Product Details</th>
          <th class="text-center history-order-columns py-3 px-1 history-head">Amount</th>
          <th class="text-center history-order-columns py-3 px-1 history-head">Action</th>
          <th class="text-center history-order-columns py-3 px-1 history-head"></th>

          <!-- <div class="btn-group py-2 downarrow">
            <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <span class=" Sort-by">Sort by</span>
            </button>
            <ul class="dropdown-menu shadow pointer p-2 pt-0">

              <hr class="m-2">
              <div class="dropdown-item d-flex pointer p-2">
                <input type="radio" name="sort" value="volvo">
                <div class="ms-2 Sort-by">Completed</div>
              </div>
              <div class="dropdown-item d-flex pointer p-2">
                <input type="radio" name="sort" value="saab">
                <div class="ms-2 Sort-by">Cancel</div>
              </div>
              <div class="dropdown-item d-flex pointer p-2">
                <input type="radio" name="sort" value="audi">
                <div class="ms-2 Sort-by">Reject</div>
              </div>
            </ul>
          </div> -->
        </tr>
      </thead>
      <tbody class="history-body mt-3">
        <tr class="py-2 order-row history-head-solid m-0 mb-3 " *ngFor="let order of completeData">
          <td class="text-center mt-2 product-details default history-order-column py-0 px-1 product-name mt-3 ">
            {{order.ordernumber}}</td>
          <td class="history-order-column py-0 px-1 product-name d-flex flex-column justify-content-center align-items-center">
            <ul class="list-unstyled p-0 mb-1 product-details default text-center">
              <li>{{order.cartitems[0].producttitle}}</li>
            </ul>
            <div class="additional-order title-color text-center" *ngIf="order.cartitems.length > 1">+ {{order.cartitems.length - 1}} more items</div>
          </td>
          <td class="history-order-column text-center history-product-details default py-0 px-1 product-name mt-3 ">
            <span class="currency-symbol"></span>{{order.amount}}</td>
          <td class="text-center px-1 py-0  history-order-column mt-1">
            <div class="d-flex justify-content-evenly">
              <button class="btn completed px-3 py-2 d-flex" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#historyId" aria-controls="historyId" (click)="completelist(order);historyoffset()">Completed </button >
            </div>
          </td>
          <td class="history-order-column text-end">
            <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon   ps-md-2 m-0 ps-1 pointer"(click)="completelist(order);historyoffset()"data-bs-toggle="offcanvas"
            data-bs-target="#historyId" aria-controls="historyId">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- orer-delivery -->

<div class="offcanvas offcanvas-end" tabindex="-1" id="historyId" aria-labelledby="historyIdLabel" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <div class="d-flex">
      <div class="default title-color  me-4" id="historyIdLabel">Order Id</div>
      <div class="text-center  product-details  name">  {{completeOrder?.ordernumber}}</div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="border-style"></div>
  <!--Complete Order -->
  <div class="offcanvas-body py-4  overflow-auto">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
        <div class="title-color default mt-1 address">{{ completeOrder?.vendoraddress }}</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class=" d-flex">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{completeOrder?.firstname}} </h6>
        <div class="title-color default mt-1 address">{{completeOrder?.address}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div>
        <div class=" mb-0 product-details additional-info">Delivery by</div>
        <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
      </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info"> Time Slot </div>
        <div class="  mt-1 default location">{{completeOrder?.deliveryscheduledate | date:'MMM dd, E'}} | {{completeOrder?.slot}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between px-4" *ngFor="let cartitems of completeOrder?.cartitems">
        <ul class="p-0 mb-3 default complete-order-content">
          <li>{{cartitems?.producttitle}}</li>
        </ul>
        <div class="title-color default">X {{cartitems?.quantity}}</div>
        <div class="title-color default"><span class="currency-symbol"></span>{{ cartitems?.netamount | number:'1.2-2'}}</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between px-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"><span class="currency-symbol"></span>{{ completeOrder?.amount | number:'1.2-2'}}</div>
    </div>
    <div class="border-style"></div>
    <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
    <!-- body end -->
  </div>
</div>