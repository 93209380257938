import { HttpClient } from '@angular/common/http';
import { Component, ElementRef,EventEmitter,Output,Renderer2,} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
declare var bootstrap: any;

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrls: ['./new-orders.component.css'],
})
export class NewOrdersComponent {
  mobileData: any = 'orderpage-mobile';
  Display: string = 'new-order';
  date: any;
  time: any;
  timeInterval: any;
  userId: string | null =null;
  selectedMenuItem: string = 'Orders';
  currentMenuBar: 'liveOrders' | 'completed' | 'cancel' = 'liveOrders';
  // orderStatus: any = 'new-order'; // Default state is 'new-order'
  @Output() menuItemClicked = new EventEmitter<string>();
  selectedOrder: any;
  selectedOrderId: string | null = null;
  now: string | undefined;
  inProgressData: any = null;
  rejectData: any = null;
  acceptData: any[] = [];
  completelist: {
    Orderid: any;
    productDetails: any;
    orderBy: any;
    additionalinfo: any;
    deliveryBy: any;
    phone: any;
    timeSlot: any;
    amount: any;
  }[] = [];
  orderListData: any[] = [];
  cancelData = [
    {
      Orderid: '1000000030',
      productDetails: 'Donat',
      totalcount: '2',
      additionitem: '',
      orderBy: 'Vinu',
      additionalinfo: '102, Mani Street, New Town, VM City, 04082023',
      deliveryBy: 'Rajan',
      phone: '9807654321',
      timeSlot: 1727611276,
      amount: '200',
      orderStatus: 'cancel',
    },
    {
      Orderid: '1000000031',
      productDetails: 'Pepperoni Pizza',
      totalcount: '5',
      additionitem: '[count:2,{item:Rose Milk,totalcount:10}]',
      orderBy: 'Saji',
      additionalinfo: '102, SS Street, New Town, EF City, 04082023',
      deliveryBy: 'Sivakumar',
      phone: '9876543210',
      timeSlot: 1727524876,
      amount: '500',
      orderStatus: 'cancel',
    },
    {
      Orderid: '1000000032',
      productDetails: 'Creamy Alfredo Pasta',
      totalcount: '3',
      additionitem: '[count:2,{item:Rose Milk,totalcount:10}]',
      orderBy: 'Alsafa',
      additionalinfo: '102, HI Street, New Town, IJ City, 04082023',
      deliveryBy: 'Fazil',
      phone: '9087654321',
      timeSlot: 1727352076,
      amount: '300',
      orderStatus: 'cancel',
    },
  ];

  constructor( private renderer: Renderer2, public dialog: MatDialog,private element: ElementRef, private route: ActivatedRoute, private router: Router,private http: HttpClient, public auth: AuthService,) {
    document.body.addEventListener('click', function (e) {
      e.stopPropagation();
    });
    // this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.auth.userInfo.subscribe((info:any) =>{
  //  console.log(info);
   
      this.userId = info.id
      
    })
  }
  ngOnInit() {
    this.updateDateAndTime();
    this.timeInterval = setInterval(() => {
      const now = new Date();
      let hours: number = now.getHours();
      const minutes: string = String(now.getMinutes()).padStart(2, '0');
      const ampm: string = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const hoursString: string = String(hours).padStart(2, '0');
      this.time = `${hoursString}:${minutes} ${ampm}`;
    }, 1000);
    window.scroll(0, 0);
    this.orderListData = [
      {
        Orderid: '1000000011',
        productDetails: 'cold coffee',
        totalcount: '3',
        additionitem:
          '[count:2,{item:lemonjuice,totalcount:10},{item:milkshake,totalcount:5}]',
        orderBy: 'LeoDas',
        additionalinfo: '103, AB Street, New Town, CD City, 04082023',
        deliveryBy: 'Vijay',
        phone: '9087654321',
        timeSlot: 1725956576,
        amount: '200',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000012',
        productDetails: 'Chicken Biriyani',
        totalcount: '2',
        additionitem:
          '[count:2,{item:parotta,totalcount:5},{item:grill,totalcount:1}]',
        orderBy: 'Annie',
        additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
        deliveryBy: 'Aldo',
        phone: '9876543210',
        timeSlot: 1726042976,
        amount: '400',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000013',
        productDetails: 'icecream',
        totalcount: '7',
        additionitem: [],
        orderBy: 'Dafne',
        additionalinfo: '3, MK Street, New Town, RK City, 1234567',
        deliveryBy: 'Chandren',
        phone: '8796543210',
        timeSlot: 1726129376,
        amount: '70',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000014',
        productDetails: 'Masala Dosa',
        totalcount: 4,
        additionitem: '[count:1,{item:parotta,totalcount:10}]',
        orderBy: 'Ashok',
        additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
        deliveryBy: 'Selvan',
        phone: '90876543210',
        timeSlot: 1726215776,
        amount: '550',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000010',
        productDetails: 'cold coffee',
        totalcount: '3',
        additionitem:
          '[count:2,{item:lemonjuice,totalcount:10},{item:milkshake,totalcount:5}]',
        orderBy: 'Santhosh',
        additionalinfo: '103, AB Street, New Town, CD City, 04082023',
        deliveryBy: 'Vijaykumar',
        phone: '9087654321',
        timeSlot: 1725956576,
        amount: '200',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000019',
        productDetails: 'Chicken Biriyani',
        totalcount: '2',
        additionitem:
          '[count:2,{item:parotta,totalcount:5},{item:grill,totalcount:1}]',
        orderBy: 'Annie',
        additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
        deliveryBy: 'Aldo',
        phone: '9876543210',
        timeSlot: 1726042976,
        amount: '400',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000018',
        productDetails: 'icecream',
        totalcount: '7',
        additionitem: [],
        orderBy: 'Dafne',
        additionalinfo: '3, MK Street, New Town, RK City, 1234567',
        deliveryBy: 'Chandren',
        phone: '8796543210',
        timeSlot: 1726129376,
        amount: '70',
        orderStatus: 'new-order',
      },
      {
        Orderid: '1000000017',
        productDetails: 'Masala Dosa',
        totalcount: 4,
        additionitem: '[count:1,{item:parotta,totalcount:10}]',
        orderBy: 'Ashok',
        additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
        deliveryBy: 'Selvan',
        phone: '90876543210',
        timeSlot: 1726215776,
        amount: '550',
        orderStatus: 'new-order',
      },
    ];

    const requestBody = {
      vendorId: this.userId,
      status:0
    }
  // this.http.get(environment.apiUrl +`/products/list-for-vendor?id=${this.userId}`).subscribe( (data:any)=>{
  //     console.log(data,"data");
      
  //   })
    // this.http.get(`${environment.apiUrl}/products/list?id=${this.userId}`).subscribe(
    //   (data: any) => {
    //     if (data.status === 'SUCCESS') {
    //       console.log(data);
          
    //     }})
   
  }
  ngOnDestroy() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  updateDateAndTime() {
    const date = new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    this.date = `${day}/${month}/${year}`;
  }

  rejectOrder(data: any, status: any): void {
    this.rejectData = data;
    data.orderStatus = status;
    if (this.rejectData) {
      this.orderListData = this.orderListData.filter(
        (item) => item !== this.rejectData);
      this.cancelData.unshift(this.rejectData);
      this.currentMenuBar = 'cancel';
    }
  }
  acceptOrder(order: any, status: any): void {
    this.selectedOrderId = null;
    const list = order;
    if (status === 'in-progress') {
      order.orderStatus = status;
      this.acceptData.push(list);
      this.currentMenuBar = 'liveOrders';
    }
  }
  inprogress(order: any) {
    // console.log(order, 'order');

    this.selectedOrder = order;
    if (this.selectedOrder) {
      this.Display = 'accept-order';
      this.selectedOrderId = null;
    }
  }
  deliveryOrder(data: any): void {
    this.inProgressData = data;
    if (this.inProgressData) {
      this.orderListData = this.orderListData.filter(
        (item) => item !== this.inProgressData
      );
      this.completelist.unshift(this.inProgressData);
      this.currentMenuBar = 'completed';
      this.selectedOrderId = null;
    }
  }
  complete(order: any) {
    this.selectedOrder = order;
    this.Display = 'complete-order';
  }
  reject() {
    this.Display = 'reject-order';
  }
  cancel(order: any) {
    this.selectedOrder = order;
    this.Display = 'cancel-order';
  }
  selectOrder(order: any) {
    this.Display = 'new-order';
    this.selectedOrder = order;
  }
  onClose() {
    this.selectedOrderId = null;
  }

  onMenuClick(menuItem: 'liveOrders' | 'completed' | 'cancel'): void {
    this.menuItemClicked.emit(menuItem);
    this.currentMenuBar = menuItem;
  }

  getSelectedOrderStatus(order: any) {
    this.selectedOrder = order;
  }
  convertTimestampToDate(timestamp: number) {
    if (!timestamp) {
      return 'Invalid date';
    }
    const date = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    };

    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }
}
