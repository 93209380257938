<div class="  position-relative">
  <div class=" p-md-4 bg d-flex ">

    <!-- <div class="position-absolute"> -->
    <div class="card position-absolute">
      <div class="card-body container-fluid mt-0 py-2 px-3">
        <div class="d-flex justify-content-start  ">

          <!-- Login Form Section -->
          <div class="w-100 ">
            <div class="text-center mb-4 mt-3">
              <img src="assets/images/gbstore-logo.svg" class="w-30" alt="logo">
            </div>
            <div class="text-center mb-4 mt-4">
              <label class="fw-bold logo-name logo-font">GP STORE</label>
            </div>
            <div class="">
              <!-- Login -->
              <div class="m-3" *ngIf="currentSection === 'login'">
                <h5 class="text-start mb-4 fw-bold logo-name">Vendor Login</h5>
                <form class="m-auto" (submit)="onLogin()" [formGroup]="loginForm">

                  <!-- mobile -->
                  <!-- <div class="form-group mb-3 row">
                <label for="inputMobile" class="form-label mobileLabel">Mobile number</label>
                <div class="d-flex">
                    <ngx-intl-tel-input class="d-block w-100 phone " formControlName="mobileInput"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedKingdom"
                        [maxLength]="15" [phoneValidation]="true">
                    </ngx-intl-tel-input>
                </div>
         
            
          </div>
          <div class="text-secondary text-center text-white">Or</div  > -->
                  <!-- email -->
                  <div class="form-group mb-3">
                    <label for="inputEmail" class="form-label emailLabel">Mobile number/Email id</label>
                    <input type="text" class="form-control" id="inputEmail" formControlName="emailInput"
                      placeholder="Enter your registered Mobile no/Email id" aria-describedby="emailHelp">
                  </div>
                  <!-- Custom validator error message -->
                  <div class="invalid-feedback">
                    Email or Phone Number is required
                  </div>
                  <!-- password -->
                  <div class="form-group mb-2 position-relative">
                    <label for="inputPassword" class="form-label passwordLabel">Password</label>
                    <input type="{{ passwordVisible ? 'text' : 'password' }}" class="form-control" id="inputPassword"
                      formControlName="password" placeholder="Enter your Password">
                    <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                      {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                    </mat-icon>
                  </div>
                  <!-- <div *ngIf="isSubmitting && f['password'].errors" class="invalid-feedback">
                   <div *ngIf="f['password'].errors['required']">Password is required</div>
                   </div> -->

                  <!-- remember me -->
                  <div class="form-group row mb-4">
                    <div class="col-6">
                      <input type="checkbox" class="form-check-input" id="checkBox" formControlName="rememberme">
                      <label class="form-check-label remember text-white" for="checkBox">Remember me</label>
                    </div>
                    <!-- forgot password -->
                    <div class="col-6 text-end">
                      <a class="forgetPassword cursor" (click)="showForgotPassword()">Forgot password?</a>
                    </div>
                  </div>

                  <!-- login button -->
                  <div class="form-group mb-2">
                    <button type="submit" class="btn loginBtn w-100" [disabled]="isFormInvalid()">Login</button>
                  </div>
                </form>

              </div>
              <!-- Forgot Password -->
              <div class="m-3" *ngIf="currentSection === 'forgetPassword'">
                <h5 class="text-start mb-4 fw-bold logo-name">Vendor Login</h5>
                <form class="m-auto" (submit)="onForgotPassword()" [formGroup]="forgotPasswordForm">
                  <div class="form-group mb-4">
                    <label for="forgetPasswordInput" class="form-label forgetPasswordLabel">Mobile number</label>
                    <input type="text" class="form-control" id="forgetPasswordInput" formControlName="emailInput"
                      placeholder="Enter your registered Mobile number">
                    <!-- <div *ngIf="isSubmitting && forgotPasswordForm.controls['emailInput'].errors"
                    class="invalid-feedback">
                    <div *ngIf="forgotPasswordForm.controls['emailInput'].errors['required']">Email is
                        required</div> 
                </div>-->
                  </div>
                  <div class="form-group pt-4">
                    <button type="submit" class="btn loginBtn w-100 d-block " (click)="showOtpVerification()">Sent
                      OTP</button>
                  </div>

                </form>
              </div>
              <!-- OTP Verification -->
              <div class="m-3" *ngIf="currentSection === 'otpVerification'">
                <h5 class="text-start mb-4 fw-bold logo-name">Vendor Login</h5>
                <form class="m-auto" (submit)="onOtpVerification()" [formGroup]="otpVerificationForm">
                  <div class="form-group mb-4">
                    <label for="inputOtp" class="form-label otpLabel">OTP Verification</label>
                    <input type="text" class="form-control" id="inputOtp" formControlName="otp" placeholder="Enter OTP">
                    <!-- <div *ngIf="isSubmitting && otpVerificationForm.controls['otp'].errors"
                    class="invalid-feedback">
                    <div *ngIf="otpVerificationForm.controls['otp'].errors['required']">OTP is required
                    </div>
                </div> -->
                  </div>
                  <div class="form-group pt-4">
                    <button type="submit" class="btn loginBtn w-100 d-block" (click)="showChangePassword()">Change
                      Password</button>
                  </div>

                </form>
              </div>
              <!-- Change Password -->
              <div class="m-3" *ngIf="currentSection === 'changePassword'">
                <h5 class="text-start mb-4 fw-bold logo-name">Vendor Login</h5>
                <form class="m-auto" (submit)="onChangePassword()" [formGroup]="changePasswordForm">
                  <div class="form-group mb-3 position-relative">
                    <label for="inputNewPassword" class="form-label newPasswordLabel">New Password </label>
                    <input type="password" class="form-control" id="inputNewPassword" formControlName="newPassword"
                      type="{{ passwordVisible ? 'text' : 'password' }}" placeholder="Enter your password" required>
                    <!-- <div *ngIf="isSubmitting && changePasswordForm.controls['newPassword'].errors"
                  class="invalid-feedback">
                  <div *ngIf="changePasswordForm.controls['newPassword'].errors['required']">New password
                      is required</div>
                  <div *ngIf="changePasswordForm.controls['newPassword'].errors['minlength']">Password
                      must be at least 8 characters long</div>
              </div> -->
                    <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                      {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                    </mat-icon>
                    <!-- <span class="validation">Password contains upper & lowercase letters, symbols, numbers, and
                  at least 8 characters</span> -->
                  </div>
                  <div class="form-group mb-3 position-relative">
                    <label for="inputConfirmPassword" class="form-label confirmPasswordLabel">Confirm New
                      Password</label>
                    <input type="password" class="form-control" id="inputConfirmPassword"
                      formControlName="confirmPassword" type="{{ passwordVisibleConfirm ? 'text' : 'password' }}"
                      placeholder="Enter your password" required>
                    <!-- <div *ngIf="isSubmitting && changePasswordForm.controls['confirmPassword'].errors"
                  class="invalid-feedback">
                  <div *ngIf="changePasswordForm.controls['confirmPassword'].errors['required']">Confirm
                      password is required</div>
              </div> -->
                    <mat-icon class="pwd-eye" (click)="togglePasswordVisibilityConfirm()">
                      {{ passwordVisibleConfirm ? 'visibility' : 'visibility_off' }}
                    </mat-icon>
                  </div>
                  <div class="form-group mb-2 pt-4">
                    <button type="submit" class="btn loginBtn w-100 d-block">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>