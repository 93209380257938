import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
declare var bootstrap: any;
@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.css'],
})
export class OrdersPageComponent implements OnInit {
  mobileData:any ="orderpage-mobile";
  selectedMenuItem: string = 'Orders';
  @Output() menuItemClicked = new EventEmitter<string>();

  constructor(private renderer: Renderer2,public dialog: MatDialog, private element :ElementRef, private route: ActivatedRoute,private router: Router,) {
  }

  ngOnInit() {
  }

  onMenuItemSelected(menuItem: string): void {
    this.selectedMenuItem = menuItem;
    switch (menuItem) {
      case 'Orders':
        this.router.navigate(['/orders-page/new-orders']);
        break;
      case 'FoodItems':
        this.router.navigate(['/orders-page/food-items-page']);
        break;
      case 'Financial':
        this.router.navigate(['/orders-page/financial-page']);
        break;
      case 'History':
        this.router.navigate(['/orders-page/history-page']);
        break;
      default:
        break;
    }
  }


}
