import { Injectable, OnInit } from '@angular/core'; 
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, } from '@angular/common/http'; 
import { catchError, from, mergeMap, Observable, of, switchMap, throwError } from 'rxjs'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
 
 
 @Injectable() 
    export class AuthInterceptor implements HttpInterceptor { 
      accessToken: string | null = null; 
      private  sessionExpired = false;
   
        constructor(private authService: AuthService, private snackBar: MatSnackBar,private router: Router,  private messageService: MessageService) {
            this.authService.accessToken.subscribe({ 
            next: (token: string | null) => { 
             if (typeof token === "string") this.accessToken = token; 
             this.sessionExpired = false;
        } }) }
        intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            const userToken = localStorage.getItem('accessToken');
            
            const modifiedReq = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${userToken}`)
            });
        
            return next.handle(modifiedReq).pipe(
              catchError((error: HttpErrorResponse) => {
                if (error.error.errors === 'Authentication Token has expired' && !this.sessionExpired) {
                  this.sessionExpired = true;
                  this.showToast();
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('UserType');
                  localStorage.removeItem('userName');
                  localStorage.removeItem('userId');
                 
                  this.router.navigate(['/login']);
                }
                // Forward any other error
                return throwError(error);
                // return throwError(() => new Error(error.message));
              })
            );
          }
        // intercept(req: HttpRequest<any>, next: HttpHandler):
        //  Observable<HttpEvent<any>> {
        //      const userToken = this.accessToken; 
        //      const modifiedReq = req.clone({
        //          headers: req.headers.set('Authorization', `Bearer ${userToken}`), 
        //         }); 
        //         return next.handle(modifiedReq); 
        //     } 
        
        // }
        showToast() {
          this.messageService.add({ severity: 'warn', summary: 'Session Expired', detail: 'Please log in again.' ,sticky: true });
        }
       
        }