<div class="d-lg-block d-none mt-4">
  <div class="row">
    <div class="text-start col-6">
      <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('liveOrders')"
        [class.selected]="currentMenuBar === 'liveOrders'"
        [ngClass]="{'selected-title': currentMenuBar === 'liveOrders'}">Live Orders</div>
      <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('completed')"
        [class.selected]="currentMenuBar === 'completed'"
        [ngClass]="{'selected-title': currentMenuBar === 'completed'}">Completed</div>
      <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('cancel')"
        [class.selected]="currentMenuBar === 'cancel'" [ngClass]="{'selected-title': currentMenuBar === 'cancel'}">
        Cancel</div>
    </div>
    <div class="d-flex justify-content-end col-6">
      <span class="date default mt-2">{{ date }} - {{ time }}</span>
    </div>
  </div>
</div>

<div class="  d-lg-none d-block mt-4">
  <div class="text-start d-flex justify-content-between">
    <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('liveOrders')"
      [class.selected]="currentMenuBar === 'liveOrders'"
      [ngClass]="{'selected-title': currentMenuBar === 'liveOrders'}">Live Orders</div>
    <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('completed')"
      [class.selected]="currentMenuBar === 'completed'" [ngClass]="{'selected-title': currentMenuBar === 'completed'}">
      Completed</div>
    <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('cancel')"
      [class.selected]="currentMenuBar === 'cancel'" [ngClass]="{'selected-title': currentMenuBar === 'cancel'}">
      Cancel</div>
  </div>


</div>
<!-- order-details table -->
<div class="border"></div>
<div class="  d-lg-none d-block mt-md-0 mt-3">
  <div class="d-flex justify-content-end ">
    <span class="date default mt-2">{{ date }} - {{ time }}</span>
  </div>
</div>
<div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'liveOrders'">
  <table class="title-table mt-2">
    <thead class="table-head py-3">
      <tr class="title-table-head ">
        <th class="text-center order-column p-3 px-1">Order ID</th>
        <th class="text-center product-column p-3  px-1">Product Details</th>
        <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
        <th class="text-center delivery-column p-3 px-1">Delivery by</th>
        <th class="text-center time-column p-3 px-1">Time Slot</th>
        <th class="text-center amount-column p-3 px-1">Amount</th>
        <th class="text-center action-column p-3 px-1">Action</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of orderListData">
        <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3">
          {{order.Orderid}}</td>
        <td class="product-column py-0 px-1">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
            <li>{{order.productDetails}}</li>
          </ul>
          <div class="additional-order title-color text-center">+ 2 more items</div>
        </td>
        <td class="orderBy-coloum py-0 px-1">
          <div class="text-center mb-0  product-details default product-name">{{order.orderBy}}</div>
          <div class="text-truncate additional-items mt-2 location">{{order.additionalinfo}}</div>
        </td>
        <td class="px-md-1 delivery-column py-0 px-1">
          <div class="text-center mb-0 product-details default product-name">{{order.deliveryBy}}</div>
          <div class="text-center additional-items mt-2 location product-name">+91 {{order.phone}}</div>
        </td>
        <td class="px-md-1 time-column py-0 px-1">
          <div class="text-center mb-0 product-details default">{{convertTimestampToDate(order.timeSlot)}}</div>
          <div class="text-center mt-2 additional-items location">2.00 pm to 5.00pm</div>
        </td>
        <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
          <span class="currency-symbol"></span>{{order.amount}}</td>
        <td class="text-center px-1 py-0 mt-1 action-column">
          <div class="d-flex justify-content-evenly">
            <div *ngIf="order.orderStatus === 'new-order'">
              <button class="btn new-order  px-2 py-2" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" (click)="selectOrder(order)">
                New Order
              </button>
              <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1">
            </div>
            <div *ngIf="order.orderStatus === 'in-progress'">
              <button class="btn progress-order  px-2 py-2" type="button" data-bs-toggle="offcanvas" data-bs-keyboard="false" 
              data-bs-backdrop="false" data-bs-target="#offcanvasRight"aria-controls="offcanvasRight" (click)="inprogress(order)">
                In Progress </button>
              <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1">
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'completed'">
  <table class="title-table mt-2">
    <thead class="table-head py-3">
      <tr class="title-table-head ">
        <th class="text-center order-column p-3 px-1">Order ID</th>
        <th class="text-center product-column p-3  px-1">Product Details</th>
        <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
        <th class="text-center delivery-column p-3 px-1">Delivery by</th>
        <th class="text-center time-column p-3 px-1">Time Slot</th>
        <th class="text-center amount-column p-3 px-1">Amount</th>
        <th class="text-center action-column p-3 px-1">Action</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of completelist">
        <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3">
          {{order.Orderid}}</td>
        <td class="product-column py-0 px-1">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
            <li>{{order.productDetails}}</li>
          </ul>
          <div class="additional-order title-color text-center">+ 2 more items</div>
        </td>
        <td class="orderBy-coloum py-0 px-1">
          <div class="text-center mb-0  product-details default product-name">{{order.orderBy}}</div>
          <div class="text-truncate additional-items mt-2 location">{{order.additionalinfo}}</div>
        </td>
        <td class="px-md-1 delivery-column py-0 px-1">
          <div class="text-center mb-0 product-details default product-name">{{order.deliveryBy}}</div>
          <div class="text-center additional-items mt-2 location product-name">+91 {{order.phone}}</div>
        </td>
        <td class="px-md-1 time-column py-0 px-1">
          <div class="text-center mb-0 product-details default">{{convertTimestampToDate(order.timeSlot)}}</div>
          <div class="text-center mt-2 additional-items location">2.00 pm to 5.00pm</div>
        </td>
        <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
          <span class="currency-symbol"></span>{{order.amount}}</td>
        <td class="text-center px-2 py-0 mt-1 action-column">
          <div class="d-flex justify-content-evenly">
            <button class="btn completed px-2 py-2" type="button" data-bs-toggle="offcanvas" data-bs-keyboard="false"
              data-bs-backdrop="false" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
              (click)="complete(order)">Completed</button>
            <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'cancel'">
  <table class="title-table mt-2">
    <thead class="table-head py-3">
      <tr class="title-table-head ">
        <th class="text-center order-column p-3 px-1">Order ID</th>
        <th class="text-center product-column p-3  px-1">Product Details</th>
        <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
        <th class="text-center delivery-column p-3 px-1">Delivery by</th>
        <th class="text-center time-column p-3 px-1">Time Slot</th>
        <th class="text-center amount-column p-3 px-1">Amount</th>
        <th class="text-center action-column p-3 px-1">Action</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of cancelData">
        <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3">
          {{order.Orderid}}</td>
        <td class="product-column py-0 px-1">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
            <li>{{order.productDetails}}</li>
          </ul>
          <div class="additional-order title-color text-center">+ 2 more items</div>
        </td>
        <td class="orderBy-coloum py-0 px-1">
          <div class="text-center mb-0  product-details default product-name">{{order.orderBy}}</div>
          <div class="text-truncate additional-items mt-2 location">{{order.additionalinfo}}</div>
        </td>
        <td class="px-md-1 delivery-column py-0 px-1">
          <div class="text-center mb-0 product-details default product-name value"></div>
          <!-- <div class="text-center additional-items mt-2 location product-name">+91 {{order.phone}}</div> -->
        </td>
        <td class="px-md-1 time-column py-0 px-1">
          <div class="text-center mb-0 product-details default">{{convertTimestampToDate(order.timeSlot)}}</div>
          <div class="text-center mt-2 additional-items location">2.00 pm to 5.00pm</div>
        </td>
        <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
          <span class="currency-symbol"></span>{{order.amount}}</td>
        <td class="text-center px-2 py-0 mt-1 action-column">
          <div class="d-flex justify-content-evenly">
            <button *ngIf="order.orderStatus === 'cancel'" class="btn cancel px-md-3 px-2 py-2" type="button"
              data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
              (click)="cancel(order)">Cancel</button>
            <button *ngIf="order.orderStatus === 'reject'" class="btn reject px-md-3 px-2 py-2" type="button"
              data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
              (click)="reject()">Reject</button>
            <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- orer-delivery -->

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" 
data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <div class="d-flex">
      <div class="default title-color  me-4" id="offcanvasRightLabel">Order Id</div>
      <div class="text-center  product-details  name"> {{selectedOrder?.Orderid}}</div>
    </div>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="border-style"></div>
  <!-- New Order -->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'new-order'">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
        <div class="title-color default mt-1 address">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.orderBy}} </h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.additionalinfo}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <img src="/assets/images/loading.svg" class="me-3 mt-1" alt="loading">
      <div class="order-wait mt-1">Waiting for Delivery Partner</div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info">{{convertTimestampToDate(selectedOrder?.timeSlot)}}</div>
        <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between px-4">
        <ul class=" p-0 mb-3 default order-content">
          <!-- <li>{{selectedOrder?.additionitem}}</li> -->
          <li>Mutton Biriyani</li>
        </ul>
        <div class="title-color default">* 2</div>
        <div class="title-color default"> <span class="currency-symbol"></span>150</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between px-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"> <span class="currency-symbol"></span>550</div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between mb-4 my-4">
      <button type="submit" class="btn d-block m-auto reject-order ms-2 default title-color" (click)="rejectOrder(selectedOrder, 'reject')"
       data-bs-dismiss="offcanvas" aria-label="Close">Reject Order</button>
      <button type="submit" class="btn d-block m-auto accept-order me-2 default"
        (click)="acceptOrder(selectedOrder,'in-progress')" data-bs-dismiss="offcanvas" aria-label="Close">Accept Order</button>
    </div>
    <!-- body end -->
  </div>
  <!-- Reject Order -->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'reject-order'">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
        <div class="title-color default mt-1 address">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">Santhosh </h6>
        <div class="title-color default mt-1 address">151, Sagotharar Street,Ganesapuram,Nagercoil,629001</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div class="value"> </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info">Time Slot</div>
        <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between px-4">
        <ul class="p-0 mb-3 default order-content">
          <li>Chicken Biriyani</li>
        </ul>
        <div class="title-color default">* 1</div>
        <div class="title-color default"><span class="currency-symbol"></span>150</div>
      </div>
      <div class="d-flex justify-content-between px-4 order-content">
        <ul class="p-0 mb-3  default">
          <li>Mutton Biriyani</li>
        </ul>
        <div class="title-color default">* 2</div>
        <div> <span class="currency-symbol"></span>400</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between px-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"> <span class="currency-symbol"></span>550</div>
    </div>
    <div class="border-style"></div>
    <div class="text-center mb-4 my-4 reject-comment">Order rejected</div>
    <!-- body end -->
  </div>
  <!-- Accept Order - Ready to Delivery Order-->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'accept-order'">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
        <div class="title-color default mt-1 address">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.orderBy}}</h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.additionalinfo}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div>
        <div class=" mb-0 product-details additional-info">DeliveryBy</div>
        <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
      </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div>
        <div class=" mb-0 product-details additional-info">{{convertTimestampToDate(selectedOrder?.timeSlot)}}</div>
        <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between px-4">
        <ul class="p-0 mb-3 default order-content">
          <!-- <li>{{selectedOrder?.additionitem}}</li> -->
          <li>Mutton Biriyani</li>
        </ul>
        <div class="title-color default">* {{selectedOrder?.additionitemTotal}}</div>
        <div class="title-color default"> <span class="currency-symbol"></span>150</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between px-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"> <span class="currency-symbol"></span>550</div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between mb-4 my-4">
      <button type="submit" class="btn d-block m-auto delivery-order default w-100"
        (click)="deliveryOrder(selectedOrder)" data-bs-dismiss="offcanvas" aria-label="Close">Order ready to
        delivery</button>
    </div>
    <!-- body end -->
  </div>
  <!--Complete Order -->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'complete-order'">
    <div class="d-flex my-2">
      <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
        <div class="title-color default mt-1 address">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.orderBy}} </h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.additionalinfo}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div>
        <div class=" mb-0 product-details additional-info">Delivery by</div>
        <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
      </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info">{{convertTimestampToDate(selectedOrder?.timeSlot)}}</div>
        <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between px-4">
        <ul class="p-0 mb-3 default order-content">
          <!-- <li>{{selectedOrder?.additionitem}}</li> -->
          <li>Mutton Biriyani</li>
        </ul>
        <div class="title-color default">* {{selectedOrder?.additionitemTotal}}</div>
        <div class="title-color default"> <span class="currency-symbol"></span>150</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between px-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"> <span class="currency-symbol"></span>550</div>
    </div>
    <div class="border-style"></div>
    <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
    <!-- body end -->
  </div>
  <!--Cancelled Order -->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'cancel-order'">
    <div class="d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">GP Foods </h6>
        <div class="title-color default mt-1 address">Vadachery bus stand, Nagercoil, Tamil Nadu 629001</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.orderBy}} </h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.additionalinfo}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div class="value"> </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info">{{convertTimestampToDate(selectedOrder?.timeSlot)}}</div>
        <div class="  mt-1 default location"> Aug 22, Thu | 2.00 pm to 5.00pm</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between px-4">
        <ul class="p-0 mb-3 default order-content">
          <!-- <li>{{selectedOrder?.additionitem}}</li> -->
          <li>Mutton Biriyani</li>
        </ul>
        <div class="title-color default">* {{selectedOrder?.additionitemTotal}}</div>
        <div class="title-color default"> <span class="currency-symbol"></span>150</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between px-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"> <span class="currency-symbol"></span>550</div>
    </div>
    <div class="border-style"></div>
    <div class="text-center mb-4 my-4 cancel-comment">Order cancelled</div>
    <!-- body end -->
  </div>
</div>