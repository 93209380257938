
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/authguard/authguard';
import { FinancialComponent } from './financial/financial.component';
import { FoodItemsComponent } from './food-items/food-items.component';
import { HistoryComponent } from './history/history.component';
import { LoginComponent } from './login/login.component';
import { NewOrdersComponent } from './new-orders/new-orders.component';
import { OrdersPageComponent } from './orders-page/orders-page.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent }, 
  { path: '', redirectTo: '/login', pathMatch: 'full' }, 
  { 
    path: 'orders-page', 
    component: OrdersPageComponent,
    children: [
      { path: '', redirectTo: 'new-orders', pathMatch: 'full' }, 
      { path: 'new-orders', component: NewOrdersComponent, canActivate: [AuthGuard] }, 
      { path: 'food-items-page', component: FoodItemsComponent, canActivate: [AuthGuard] },
      { path: 'financial-page', component: FinancialComponent, canActivate: [AuthGuard] },
      { path: 'history-page', component: HistoryComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: '**', redirectTo: '/login' },
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
