import { Injectable, OnInit } from '@angular/core'; 
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, } from '@angular/common/http'; 
import { catchError, from, mergeMap, Observable, of, switchMap, throwError } from 'rxjs'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
 
 
 @Injectable() 
    export class AuthInterceptor implements HttpInterceptor { 
      accessToken: string | null = null; 
      private  sessionExpired = false;
      private invalidToast = false;
   
        constructor(private authService: AuthService, private snackBar: MatSnackBar,private router: Router,  private messageService: MessageService) {
            this.authService.accessToken.subscribe({ 
            next: (token: string | null) => { 
             if (typeof token === "string") this.accessToken = token; 
             this.sessionExpired = false;
        } }) }
        intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          const userToken = localStorage.getItem('accessToken');
          // console.log('Interceptor: User Token:', userToken);
        
          if (userToken) {
            const modifiedReq = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${userToken}`)
            });
        
            return next.handle(modifiedReq).pipe(
              catchError((error: HttpErrorResponse) => {
                // console.error('HTTP Error:', error);
        
                if (error.error.errors && error.error.errors === 'Authentication Token has expired') {
                  // Handle session expiry only once
                  if (!this.sessionExpired) {
                    this.sessionExpired = true;
                    // console.log('Session expired handling initiated');
                    this.clearSession();
                    this.showToast();
                  }
                }
        
                return throwError(error);
              })
            );
          }
        
          return next.handle(req);
        }
        
        
        // intercept(req: HttpRequest<any>, next: HttpHandler):
        //  Observable<HttpEvent<any>> {
        //      const userToken = this.accessToken; 
        //      const modifiedReq = req.clone({
        //          headers: req.headers.set('Authorization', `Bearer ${userToken}`), 
        //         }); 
        //         return next.handle(modifiedReq); 
        //     } 
        
        // }
        showToast() {
          this.messageService.add({ severity: 'warn', summary: 'Session Expired', detail: 'Please log in again.' ,sticky: true });
        }
        clearSession() {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('UserType');
          localStorage.removeItem('userName');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        // showToastContent() {
        //   this.messageService.add({ severity: 'warn', summary: 'Session Expired',    detail: 'Please log in valid email.' ,sticky: true });
        // }

        }