import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { DatefotmatService } from '../service/datefotmat.service';
declare var $: any;
declare var bootstrap: any;

declare global {
  interface JQuery {
    datepicker(options?: any): JQuery;
    datepicker(method: string): JQuery;
    datepicker(method: string, option: any): JQuery;
  }
}
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
  showShadow: boolean = false;
  showSize: boolean = true;
  showSort: boolean = false;
  rangeDates: Date[] = [];
  date!: Date;
  inputFieldValue: string = '';
  startDateValue: string = '';
  endDateValue: string = '';
  maxDate: Date;
  today: Date = new Date();
  calendarStyles: { [key: string]: string } = {};
  isInline: boolean = window.innerWidth > 768;
  userId: string | null =null;
  completeData :any[]=[];
  completeOrder: any;
  orderListData = [
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
  ];
  constructor(private renderer: Renderer2,private el: ElementRef,private dateService: DatefotmatService,private http: HttpClient, public auth: AuthService,) {
    this.maxDate = new Date();
    // this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.auth.userInfo.subscribe((info:any) =>{
      this.userId = info.id
    })
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }
  toggleDropdown() {
    const sortButton = document.getElementById('sortButton');
    const dropdownMenu = document.getElementById('dropdownMenu');

    if (sortButton && dropdownMenu) {
      if (sortButton.style.display === 'none') {
        // If the dropdown is shown, hide it and show the button
        sortButton.style.display = 'inline-block';
        dropdownMenu.style.display = 'none';
      } else {
        // If the button is shown, hide the button and show the dropdown
        sortButton.style.display = 'none';
        dropdownMenu.style.display = 'block';
      }
    } else {
      console.error('Sort button or dropdown menu element not found');
    }
  }

  ngAfterViewInit(): void {}
  onClose() {
    // this.Display = 'new-order';
  }
  updateDate() {
    // Clear previous values
    this.startDateValue = '';
    this.endDateValue = '';

    // Set start and end date values
    if (this.rangeDates.length > 0 && this.rangeDates[0]) {
      this.startDateValue = this.dateService.formatDate(this.rangeDates[0]);
      // console.log( this.startDateValue," this.startDateValue");
      

    }
    if (this.rangeDates.length === 2 && this.rangeDates[1]) {
      this.endDateValue = this.dateService.formatDate(this.rangeDates[1]);
      // console.log( this.endDateValue," this.endDateValue");

    }
    const startTimestamp = new Date(this.rangeDates[0]).getTime();
const endTimestamp = new Date(this.rangeDates[1]).getTime();
    // console.log(this.startDateValue, this.endDateValue,startTimestamp,endTimestamp,this.rangeDates[0],this.rangeDates[1]);
    this.http.post(environment.apiUrl + `/vendororders/order-list`,{vendorId: this.userId,orderStatus: "ready",startDate:startTimestamp,endDate:endTimestamp},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
       }
    //  console.log(data,"data vendor ");
     this.completeData = data.orders_output;
    })

  }

  toDate(date: any): Date {
    return new Date(date.year, date.month , date.day); // Month is 0-indexed in JavaScript
  }
  
  // Check if the date is the start of the range
  isStartDate(date: any): boolean {
    if (!this.rangeDates || !this.rangeDates[0]) {
      return false;
    }
    return this.toDate(date).getTime() === this.rangeDates[0].getTime();
  }
  
  // Check if the date is the end of the range
  isEndDate(date: any): boolean {
    if (!this.rangeDates || !this.rangeDates[1]) {
      return false;
    }
    return this.toDate(date).getTime() === this.rangeDates[1].getTime();
  }
  
  // Check if the date is between the start and end dates
  isInRange(date: any): boolean {
    if (!this.rangeDates || !this.rangeDates[0] || !this.rangeDates[1]) {
      return false;
    }
    const currentDate = this.toDate(date).getTime();
    return currentDate > this.rangeDates[0].getTime() && currentDate < this.rangeDates[1].getTime();
  }
  @HostListener('window:resize', [])
  onResize(): void {
    this.isInline = window.innerWidth > 768; // Switch between inline and popup
    this.updateCalendarStyles();
  }
  updateCalendarStyles(): void {
    if (window.innerWidth >= 768) {
    //   this.calendarStyles = { width: '100%', fontSize: '14px' }; // Example styles for smaller screens
    // } else {
      this.calendarStyles = { width: 'auto', fontSize: '16px' }; // Example styles for larger screens
    }
  }

  completelist(order:any){
    this.completeOrder = order;
    this.http.get(environment.apiUrl +`/vendororders/order-get?id=${order.id}&vendorId=${this.userId}`).subscribe((data: any) => {
        this.completeOrder = data.orders;
      });
  }
  historyoffset(): void {
    const offcanvas = document.getElementById('historyId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }

    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
}
