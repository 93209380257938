import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatefotmatService {

  constructor() { }
   formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
