import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component,ElementRef,EventEmitter,Input,OnInit,Renderer2,} from'@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
declare var bootstrap: any;
@Component({
  selector: 'app-food-items',
  templateUrl: './food-items.component.html',
  styleUrls: ['./food-items.component.css'],
})
export class FoodItemsComponent implements OnInit {
  selectedStockItem: string = '';
  @Input() stockClicked = new EventEmitter<string>();
  currentstockBar: 'inStock' | 'outofStock' = 'inStock';
  selectedItem: any = null;
  userId: string | null =null;
  availableItems:any[] = [];
  unavailableItems:any[] = [];
  availableItems1:any
  availablecount:number = 0;
  unavailablecount:number = 0;
  markasUnavailabe:any[] = [];
  markasAvailabe:any[] = [];
 
  constructor(private renderer: Renderer2, private elementRef: ElementRef,private http: HttpClient,public auth: AuthService ,  private router: Router, ){
    this.auth.userInfo.subscribe((info:any) =>{
          this.userId = info.id
        })
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    // /products/get-for-vendor?id=09ae2561-ae65-410e-9049-5cb265cb1741&vendorId=81d88dc6-541a-4611-b8a0-9345b94379ed


  
    const requestBody = {
      // vendorId: "6d693b17-f6b4-4cb2-a728-c09893f9451c",
      vendorId: this.userId,
      status:0
    }
    this.http.post(environment.apiUrl + `/products/list-for-vendor`,requestBody,{responseType:'json'}).subscribe( (data:any)=>{
      this.unavailablecount = data.count
     data.products_output.forEach((product: any) => {
        product.src = [];
          this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${product.id}`).subscribe((data: any) => {
            if (data.status === "SUCCESS") {
              if (data.uploads.length > 0) {
                this.http.get(environment.apiUrl + `/uploads/get?id=${data.uploads[0].id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    product.src.push(res.uploads.base64data);
                  }
                });
              }
            }
        });
      });
      this.unavailableItems.push(...data.products_output)
    })

    const requestBody1 = {
      vendorId: this.userId,
      status:1
    }
    this.http.post(environment.apiUrl + `/products/list-for-vendor`,requestBody1,{responseType:'json'}).subscribe( (data:any)=>{
      this.availablecount = data.count
      data.products_output.forEach((product: any) => {
        product.src = [];
          this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${product.id}`).subscribe((data: any) => {
            if (data.status === "SUCCESS") {
              if (data.uploads.length > 0) {
                this.http.get(environment.apiUrl + `/uploads/get?id=${data.uploads[0].id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    product.src.push(res.uploads.base64data);
                  }
                });
              }
            }
        });
      });
      this.availableItems.push(...data.products_output)
    })
  }

// taxid
  onStockSelected(menuItem:any): void {
    // this.stockClicked.emit(menuItem);
    this.currentstockBar = menuItem;
 
  }

  onClose() {
    this.selectedItem = null;
  }
  onItemClick(data: any): void {
    this.selectedItem = data;
  }
  onAvailable(id:any) {
    if (this.selectedItem) {
      this.selectedItem.isOutOfStock = true;
      this.currentstockBar = 'outofStock';
      this.selectedItem = null;
      this.http.get(environment.apiUrl + `/products/get-for-vendor?id=${id}&vendorId=${this.userId}`,{responseType:'json'}).subscribe( (data:any)=>{
         const src: any[] = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${data.products.id}`).subscribe((data: any) => {
              if (data.status === "SUCCESS") {
                if (data.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${data.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                     src.push(res.uploads.base64data);
                    }
                  });
                }
              }
          });
         
          this.unavailableItems.unshift({
            id : data.products.id,
            status: data.products.status,
            title: data.products.title,
            src: src,
            vendorid: data.products.vendorid,
            imageinfo: data.products.imageinfo,
            minprice:data.products.minprice,
          });
        this.availableItems = this.availableItems.filter(item => item.id !== data.products.id);
      })
    }
  }

  onUnavailable(id:any): void {
    if (this.selectedItem) {
        this.http.get(environment.apiUrl + `/products/get-for-vendor?id=${id}&vendorId=${this.userId}`,{responseType:'json'}).subscribe( (data:any)=>{
           const src: any[] = [];
              this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${data.products.id}`).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  if (data.uploads.length > 0) {
                    this.http.get(environment.apiUrl + `/uploads/get?id=${data.uploads[0].id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                       src.push(res.uploads.base64data);
                      }
                    });
                  }
                }
            });
          
            this.availableItems.unshift({
              id : data.products.id,
              status: data.products.status,
              title: data.products.title,
              src: src,
              vendorid: data.products.vendorid,
              imageinfo: data.products.imageinfo,
              minprice:data.products.minprice,

            });
          
          this.unavailableItems = this.unavailableItems.filter(item => item.id !== data.products.id);
        })

        // Add the 'outof-stock' class to the selected item
        this.selectedItem.class = 'outof-stock';
        this.currentstockBar = 'inStock';
        // Clear the selected item
        this.selectedItem = null;
    }
  }

  fooditemoffset(): void {
    const offcanvas = document.getElementById('new-stockId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }

    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
}
