<div class="grid-item1 d-lg-block d-none">
  <div class="text-center mb-2 mt-3 ">
    <img src="assets/images/gbstore-logo.svg" class="w-30 logo" alt="logo">
  </div>
  <div class="text-center mb-4 mt-2">
    <label class="fw-bold store-name">GP STORE</label>
  </div>
  <hr class="sidenav-border">

  <div class="text-center mt-3 mb-2 position-relative">
    <img [src]="userprofile && userprofile.trim() !== '' ? userprofile : 'assets/images/order-logo.svg'"
      class="w-30 gp-profile" alt="logo">
    <input type="file" accept="image/*" (change)="handleLicenceChange($event)" #profileInput class="d-none">
    <div class="edit pointer " (click)="triggerUpload()">
      <img src="assets/images/edit.svg" class="w-30 edit-logo" alt="logo">
    </div>
  </div>


  <div class="text-center  mb-0">
    <label class="fw-bold foods ">GP FOODS</label>
  </div>
  <div class="text-center mt-2 row">
    <div class="col-5 px-0 text-end">
      <img src="assets/images/location.svg" class="w-30 me-2 location-icon" alt="location">
    </div>
    <div class="col-7 px-0 text-start">
      <label class="location default">Nagercoil</label>
    </div>
  </div>
  <!-- order-items -->

  <div class=" ps-3">
    <div class=" row menu-items " (click)="onMenuItemClick('Orders')" [class.select]="currentSideBar === 'Orders'">
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',false)" class=" me-2 order-img" alt="orders">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',true)" class=" me-2 order-img" alt="orders">
      </div>
      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'Orders'}">Orders</div>
      </div>
    </div>
    <div class="row menu-items " (click)="onMenuItemClick('FoodItems')" [class.select]="currentSideBar === 'FoodItems'">
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',false)" class="item-img me-2"
          alt="food-item">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',true)" class="item-img me-2"
          alt="food-item">
      </div>
      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'FoodItems'}">Food Items</div>
      </div>
    </div>
    <div class="  row menu-items" (click)="onMenuItemClick('Financial')"
      [class.select]="currentSideBar === 'Financial'">
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('financial', currentSideBar === 'Financial',false)" class="item-img me-2"
          alt="financial">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('financial', currentSideBar === 'Financial',true)" class="item-img me-2"
          alt="financial">
      </div>
      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'Financial'}">Financial</div>
      </div>
    </div>
    <div class=" row menu-items " (click)="onMenuItemClick('History')" [class.select]="currentSideBar === 'History'">
      <div class="col-3 text-center px-0 pointer default-img">
        <img [src]="getImageSrc('history', currentSideBar === 'History',false)" class="item-img me-2" alt="history">
      </div>
      <div class="col-3 text-center px-0 pointer hover-img">
        <img [src]="getImageSrc('history', currentSideBar === 'History',true)" class="item-img me-2" alt="history">
      </div>
      <div class="col-9 px-0 pointer">
        <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'History'}">History</div>
      </div>
    </div>
    <div class="row menu-items pointer" [class.select]="currentSideBar === 'LogOut'" data-bs-toggle="modal"
      data-bs-target="#staticBackdrop">
      <div class="col-3  text-center px-0 pointer default-img">
        <img src="assets/images/log-out.svg" class="item-img me-2" alt="log-out">
      </div>
      <div class="col-3  text-center px-0 pointer hover-img">
        <img src="assets/images/log-out-color.svg" class="item-img  me-2 " alt="orders">
      </div>
      <div class="col-9 px-0 pointer">
        <div class="name title-color">Logout</div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content pt-3 ">
          <div class="modal-body text-center ">
            <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
            <div class="popup-message mt-3 message-color">Do you want to Logout your account</div>
          </div>
          <div class="modal-footer d-flex justify-content-evenly p-0">
            <button class="btn signout-btn message-color m-2" data-bs-dismiss="modal" aria-label="Close">No</button>
            <div class="vr px-0 "></div>
            <button class="btn signout-btn message-color m-2" (click)="toggleSignoutPopup()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- for Mobile -->
<div *ngIf="mobileData === 'orderpage-mobile'">
  <div class=" d-lg-none d-block mobile-content">
    <div class="d-flex p-3 ps-0 pointer">
      <div class="text-start  mt-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample">
        <img src="assets/images/gbstore-logo.svg" class="w-30 logo" alt="logo">
      </div>
      <div class="text-start  mx-3 store mt-3 pointer" (click)="togglesidenav()">
        <label class="fw-bold store-name pointer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample">GP STORE</label>
      </div>

      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header px-4 pt-4 pb-0">
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body pt-0">
          <!-- <div class="text-center mt-0 mb-2 position-relative">
            <img src="assets/images/order-logo.svg" class="w-30" alt="logo">
            <div class=" edit">
              <img src="assets/images/edit.svg" class="w-30 " alt="logo">
            </div>
          </div> -->
          <div class="text-center mt-0 mb-2 position-relative">
            <img [src]="userprofile && userprofile.trim() !== '' ? userprofile : 'assets/images/order-logo.svg'"
              class="w-30 gp-profile" alt="logo">
            <input type="file" accept="image/*" (change)="handleLicenceChange($event)" #profileInput class="d-none">
            <div class="edit pointer " (click)="triggerUpload()">
              <img src="assets/images/edit.svg" class="w-30 " alt="logo">
            </div>
          </div>
          <div class="text-center  mb-0">
            <label class="fw-bold foods ">GP FOODS</label>
          </div>
          <div class="text-center mb-4 mt-2 d-flex justify-content-center row">
            <div class="col-5 px-0 text-end">
              <img src="assets/images/location.svg" class="w-30 me-2 location-icon" alt="location">
            </div>
            <div class="col-7 px-0 text-start">
              <label class="location default">Nagercoil</label>
            </div>
          </div>

          <!-- order-items -->
          <div class=" ps-3 ">
            <div class="my-4 row menu-items pointer" (click)="onMenuItemClick('Orders')"
              [class.select]="currentSideBar === 'Orders'">
              <div class="col-3 text-center px-0 pointer default-img">
                <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',false)" class="order-img me-2"
                  alt="orders">
              </div>
              <div class="col-3 text-center px-0 pointer hover-img">
                <img [src]="getImageSrc('Orders', currentSideBar === 'Orders',true)" class="order-img me-2"
                  alt="orders">
              </div>

              <div class="col-9 px-0 pointer">
                <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'Orders'}">Orders</div>
              </div>
            </div>
            <div class=" my-4 row menu-items pointer" (click)="onMenuItemClick('FoodItems')"
              [class.select]="currentSideBar === 'FoodItems'">
              <div class="col-3 text-center px-0 pointer default-img">
                <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',false)" class="item-img me-2"
                  alt="food-item">
              </div>
              <div class="col-3 text-center px-0 pointer hover-img">
                <img [src]="getImageSrc('food-item', currentSideBar === 'FoodItems',true)" class="item-img me-2"
                  alt="food-item">
              </div>

              <div class="col-9 px-0 pointer mt-1">
                <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'FoodItems'}">Food Items
                </div>
              </div>
            </div>
            <div class=" my-4 row menu-items pointer" (click)="onMenuItemClick('Financial')"
              [class.select]="currentSideBar === 'Financial'">

              <div class="col-3 text-center px-0 pointer default-img">
                <img [src]="getImageSrc('financial', currentSideBar === 'Financial',false)" class="item-img me-2"
                  alt="financial">
              </div>
              <div class="col-3 text-center px-0 pointer hover-img">
                <img [src]="getImageSrc('financial', currentSideBar === 'Financial',true)" class="item-img me-2"
                  alt="financial">
              </div>
              <div class="col-9 px-0 pointer mt-1">
                <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'Financial'}">Financial
                </div>
              </div>
            </div>
            <div class=" my-4 row menu-items pointer" (click)="onMenuItemClick('History')"
              [class.select]="currentSideBar === 'History'">
              <div class="col-3 text-center px-0 pointer default-img">
                <img [src]="getImageSrc('history', currentSideBar === 'History',false)" class="item-img me-2"
                  alt="history">
              </div>
              <div class="col-3 text-center px-0 pointer hover-img">
                <img [src]="getImageSrc('history', currentSideBar === 'History',true)" class="item-img me-2"
                  alt="history">
              </div>
              <div class="col-9 px-0 pointer mt-1">
                <div class="name title-color" [ngClass]="{'active-title': currentSideBar === 'History'}">History</div>
              </div>
            </div>
            <div class="my-5 row menu-items pointer" data-bs-toggle="modal" data-bs-target="#logoutdrop">
              <div class="col-3 text-center px-0 pointer default-img">
                <img src="assets/images/log-out.svg" class="item-img me-2" alt="log-out">
              </div>
              <div class="col-3 text-center px-0 pointer hover-img">
                <img src="assets/images/log-out-color.svg" class="item-img me-2 " alt="log-out">
              </div>
              <div class="col-9 px-0 pointer mt-1">
                <div class="name title-color">Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade " id="logoutdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content pt-3 ">
          <div class="modal-body text-center ">
            <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
            <div class="popup-message mt-3 message-color">Do you want to Logout your account</div>
          </div>
          <div class="modal-footer d-flex justify-content-evenly p-0">
            <button class="btn signout-btn message-color m-2" data-bs-dismiss="modal" aria-label="Close">No</button>
            <div class="vr px-0 "></div>
            <button class="btn signout-btn message-color m-2" (click)="toggleSignoutPopup()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>