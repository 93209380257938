import { Component, OnInit, Renderer2 } from '@angular/core';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { DatefotmatService } from '../service/datefotmat.service';
declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.css'],
})
export class FinancialComponent implements OnInit {
  selectedMenuItem: string = '';
  date: Date = new Date();
  datefotmat: string = '';
  maxDate: any;
  isCalendarVisible = false;
  selectedDate: Date = new Date();
  en: any;
  isOpen = false;
  isOpenMobile = false;
  orderListData = [
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
    {
      Orderid: '1000000011',
      productDetails: 'cold coffee',
      totalcount: '3',
      additionitem:
        '[count:2,{item1:lemonjuice,totalcount:10},{item2:milkshake,totalcount:5}]',
      orderBy: 'Santhosh',
      additionalinfo: '103, AB Street, New Town, CD City, 04082023',
      deliveryBy: 'Vijaykumar',
      phone: '9087654321',
      timeSlot: 1725956576,
      amount: '200',
    },
    {
      Orderid: '1000000012',
      productDetails: 'Chicken Biriyani',
      totalcount: '2',
      additionitem:
        '[count:2,{item1:parotta,totalcount:5},{item2:grill,totalcount:1}]',
      orderBy: 'Annie',
      additionalinfo: '90, Bl Street, New Town, DR City, 98765045',
      deliveryBy: 'Aldo',
      phone: '9876543210',
      timeSlot: 1726042976,
      amount: '400',
    },
    {
      Orderid: '1000000013',
      productDetails: 'icecream',
      totalcount: '7',
      additionitem: [],
      orderBy: 'Dafne',
      additionalinfo: '3, MK Street, New Town, RK City, 1234567',
      deliveryBy: 'Chandren',
      phone: '8796543210',
      timeSlot: 1726129376,
      amount: '70',
    },
    {
      Orderid: '1000000014',
      productDetails: 'Masala Dosa',
      totalcount: 4,
      additionitem: '[count:1,{item1:parotta,totalcount:10}]',
      orderBy: 'Ashok',
      additionalinfo: '10, AS Street, New Town, Kp City, 42023080',
      deliveryBy: 'Selvan',
      phone: '90876543210',
      timeSlot: 1726215776,
      amount: '550',
    },
  ];
  isReadOnly: boolean = false;
  dateCustomClasses: DatepickerDateCustomClasses[] | undefined;

  constructor(
    private dateService: DatefotmatService,
    private renderer: Renderer2
  ) {
    this.maxDate = new Date();
    this.updateDateCustomClasses();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.datefotmat = this.formatDate(this.date);
  }
  onMenuItemSelected(menuItem: string): void {
    this.selectedMenuItem = menuItem;
  }
  ngAfterViewInit() {
    this.setCustomDayNames();
  }
  toggleDesktopCalendar() {
    this.isOpen = !this.isOpen; // Toggle desktop datepicker
  }

  toggleMobileCalendar() {
    this.isOpenMobile = !this.isOpenMobile; // Toggle mobile datepicker
  }
  setCustomDayNames() {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayCells = document.querySelectorAll(
      '.p-datepicker table > thead > tr > th'
    );

    if (dayCells.length > 0) {
      dayCells.forEach((cell, index) => {
        // Set the custom day names
        cell.textContent = dayNames[index];
        cell.classList.add('custom-day-names'); // Add a custom class for styling
      });
    }
  }
  onClose() {
    // this.Display = 'new-order';
  }
  clickCallBack(event: Event) {}

  isNoToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() !== today.getDate() ||
      date.getMonth() !== today.getMonth() ||
      date.getFullYear() !== today.getFullYear()
    );
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  toggleCalendar() {
    this.isCalendarVisible = !this.isCalendarVisible;
  }

  display() {
    const calendarElement = document.querySelector('.p-datepicker-calendar-container');
    if (calendarElement) {
      this.renderer.addClass(calendarElement, 'visible');
      this.renderer.removeClass(calendarElement, 'hidden');
    }
    this.isCalendarVisible = false;
  }

  displayUp() {
    const calendarElement = document.querySelector('.p-datepicker-calendar-container');
    if (calendarElement) {
      this.renderer.addClass(calendarElement, 'hidden');
      this.renderer.removeClass(calendarElement, 'visible');
      this.isCalendarVisible = true;
    }
  }
  onDateSelect(): void {
    this.datefotmat = this.formatDate(this.date);
    this.isCalendarVisible = false; // Hide the calendar after selecting a date
  }
  onDateChange(date: Date): void {
    this.selectedDate = date;
    this.updateDateCustomClasses();
    // this.applyDateStyles();
  }
  updateDateCustomClasses() {
    const now = new Date();

    // Check if today is selected
    const isTodaySelected =
      this.selectedDate?.getDate() === now.getDate() &&
      this.selectedDate?.getMonth() === now.getMonth() &&
      this.selectedDate?.getFullYear() === now.getFullYear();

    this.dateCustomClasses = [
      {
        date: now,
        classes: isTodaySelected ? ['today', 'selected'] : ['bg-warning'],
      },
    ];
  }
  financialoffset(): void {
    const offcanvas = document.getElementById('financialId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }

    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
}
