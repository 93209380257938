<!-- 


<input type="text" class="form-control d-flex text-center input-bg-color datepicker" (keydown)="preventToggleDefault($event)"

placeholder="Date" [min]="startDate" [(ngModel)]="currentDate" (click)="preventToggle($event)" /> -->



<!-- <div class="grid-container">
<mat-sidenav-container >
  <app-sidenav-items (menuItemClicked)="onMenuItemSelected($event)"></app-sidenav-items>
</mat-sidenav-container>
<mat-drawer-container >
  <mat-drawer-content class="main-content">
    Main content
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
</div>
 -->

 <!-- <bs-datepicker-inline [bsValue]="bsInlineValue" 
 [datesDisabled]="disabledDates"
 ></bs-datepicker-inline>
 <input
 type="text"
 
 bsDaterangepicker
 triggers="keydown click"
 class="form-control"
 [bsConfig]="{
  dateCustomClasses,
  showWeekNumbers: false
}"
> -->
<!-- <input
  type="text"
  bsDatepicker
  triggers="keydown click"
  class="form-control "
  [maxDate]="maxDate"
  [bsConfig]="{
    dateInputFormat: 'DD MMMM YYYY',
    showWeekNumbers: false
    
  }"
  [value]="formattedDate"
  (bsValueChange)="onDateChange($event)" 
 
  style="display: inline-block; width: 300px; margin-left: 50%;"
/> -->


<!-- worked -->
<!-- <div class="content">
  <h4>datepicker should display current date:</h4>
  <div class="row">
    <div class="col-xs-12 col-12 col-md-4 form-group">
      <input type="text"
             class="form-control"
             #dp="bsDatepicker"
             bsDatepicker
             [(bsValue)]="maxDate"
             [bsConfig]="{
               dateInputFormat: 'DD MMMM YYYY',
               showWeekNumbers: false
             }"
             (bsValueChange)="onDateChange($event)">
            
    </div>
  </div>
</div> -->
<!-- end -->
<!-- <div class="content">
<div class="row">
  <div class="col-xs-12 col-12 col-md-4 form-group">
    <input type="text"
           class="form-control"
           #dp="bsDatepicker"
           bsDatepicker
           [(bsValue)]="maxDate"
           [bsConfig]="bsConfig"
           (bsValueChange)="onDateChange($event)">
      
  </div>
</div>
</div> -->





<!-- <div class="content">
  <h4>datepicker should display current date:</h4>
  <div class="row">
    <div class="col-xs-12 col-12 col-md-4 form-group">
      <div 
            #dp="bsDatepicker"
            bsDatepicker [(bsValue)]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD MMMM YYYY',
              showWeekNumbers: false,
             
            }"
             (bsValueChange)="onDateChange($event)"
            ></div>
    </div>
  </div>
</div> -->







<!-- <bs-datepicker-inline [bsValue]="bsInlineValue" 
[datesDisabled]="disabledDates"
></bs-datepicker-inline> -->


<!-- <bs-datepicker-inline 
  [bsValue]="bsInlineValue" 
  [datesDisabled]="disabledDates"
  (bsValueChange)="onDateChange($event)">
</bs-datepicker-inline> -->





<!-- <input
type="text"

bsDaterangepicker
triggers="keydown click"
class="form-control"
[bsConfig]="{
 dateCustomClasses,
 showWeekNumbers: false
}"
> -->










<!-- <div class="content">
  <h4>datepicker should display current date:</h4>
  <div class="row">
    <div class="col-xs-12 col-12 col-md-4 form-group">
      <input
        type="text"
        class="form-control"
        [maxDate]="maxDate" 
        #dp="bsDatepicker"
        bsDatepicker
        [(bsValue)]="selectedDate"
        [bsConfig]="{
          dateInputFormat: 'DD MMMM YYYY',
          showWeekNumbers: false,
         
        }"
        (bsValueChange)="onDateChange($event)" 
      />
    </div>
  </div>
</div> -->

<!-- <div class="card d-flex justify-content-center "  [ngClass]="{ 'with-value': selectedDate }">
  <input type="text" class="form-control pointer" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker
    [(bsValue)]="selectedDate" [bsConfig]="{
    dateInputFormat: 'DD MMMM YYYY',
    showWeekNumbers: false,
   
  }" [isOpen]="isOpen" [dateCustomClasses]="dateCustomClasses"(bsValueChange)="onDateChange($event)" readonly [outsideClick]="false"  (focus)="isOpen = true"/>
  <img src="/assets/images/calendar.svg" alt="calendar" class="me-2 me-lg-0 calendar-image pointer" (click)="isOpen = !isOpen" 
   /></div> -->
   <div class="card d-flex justify-content-center ">
   <input type="text" class="form-control pointer" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker
          [(bsValue)]="selectedDate" [bsConfig]="{
          dateInputFormat: 'DD MMMM YYYY',
          showWeekNumbers: false,
         
        }" (bsValueChange)="onDateChange($event)"  [outsideClick]="false" [isOpen]="isOpen" [dateCustomClasses]="dateCustomClasses" onkeydown="return false" 
     
       (click)="toggleCalendar()"/>
       <img src="/assets/images/calendar.svg" alt="calendar" class="me-2 me-lg-0 calendar-image pointer"
       (click)="isOpen = !isOpen">
       </div>
   <!-- <p-toast 
   position="bottom-center" 
   key="confirm" 
   (onClose)="onReject()" 
   [baseZIndex]="5000"[showTransformOptions]="'translateY(100%)'" 
   [showTransitionOptions]="'1000ms'" 
   [hideTransitionOptions]="'1000ms'" 
   [showTransformOptions]="'translateX(100%)'">
       <ng-template let-message pTemplate="message">
           <div class="flex flex-column align-items-start" style="flex: 1">
               <div class="flex align-items-center gap-2">
                   <p-avatar 
                       image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" 
                       shape="circle" />
                   <span class="font-bold text-900">
                       Amy Elsner
                   </span>
               </div>
               <div class="font-medium text-lg my-3 text-900">
                   {{ message.summary }}
               </div>
               <p-button size="small" label="Reply" (onClick)="onConfirm()" />
           </div>
       </ng-template>
</p-toast>
<p-button (onClick)="showConfirm()" label="View" /> -->



<!-- <div class="content">
  <h4>Datepicker should display current date:</h4>
  <form [formGroup]="myForm"> 
    <div class="row">
      <div class="col-xs-12 col-12 col-md-4 form-group">
        <input
          type="text"
          class="form-control"
          bsDaterangepicker
          triggers="keydown click"
          [(ngModel)]="startDate"
          (bsValueChange)="onDateChange( $event )"
          [bsConfig]="{ 
            containerClass: 'theme-default',
            rangeInputFormat: 'MMM DD, YYYY',
            maxDate: maxDate,
            minDate: minDate,
            
          }"
          placeholder="Select Date Range"
        >
      </div>
    </div>
  </form>
</div> -->



<!-- <form [formGroup]="myForm"> 
  <div class="form-group">
    <label for="start">Start Date</label>
    <input
      id="start"
      type="text"
      bsDatepicker
      class="form-control"
      [bsConfig]="{
        containerClass: 'theme-default', 
        dateInputFormat: 'MMM DD, YYYY',
        maxDate: maxDate,
        minDate: minDate,
        isAnimated: true,
        adaptivePosition: true
      }"
      [(ngModel)]="startDate"
      (bsValueChange)="onStartDateChange($event)"
    />
  </div>

  <div class="form-group">
    <label for="end">End Date</label>
    <input
      id="end"
      type="text"
      bsDatepicker
      class="form-control"
      [bsConfig]="{
        containerClass: 'theme-default', 
        dateInputFormat: 'MMM DD, YYYY',
        maxDate: maxDate,
        minDate: minDate,
        isAnimated: true,
        adaptivePosition: true,
        
      }"
      [(ngModel)]="endDate"
      (bsValueChange)="onChange($event)"
    />
  </div>
</form> -->
















 <!-- <div id="datepicker" data-date="12/03/2012"></div>
 <input type="hidden" id="my_hidden_input"> -->

 <!-- <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" />

<ng-template #t let-date let-focused="focused">
	<span
		class="custom-day"
		[class.focused]="focused"
		[class.range]="isRange(date)"
		[class.faded]="isHovered(date) || isInside(date)"
		(mouseenter)="hoveredDate = date"
		(mouseleave)="hoveredDate = null"
	>
		{{ date.day }}
	</span>
</ng-template>

<hr />

<pre>From: {{ fromDate | json }} </pre>
<pre>To: {{ toDate | json }} </pre> -->




<!-- <mbsc-datepicker
    [controls]="['calendar']"
    select="range"
    calendarType="month"
    [pages]="2"
    display="inline"
                    [touchUi]="true">
</mbsc-datepicker> -->














<!-- 
<div class="container">
  <form [formGroup]="datePickerForm">
    <div class="form-group">
      <label for="date">Select Date:</label>
      <input
        type="text"
        class="form-control"
        bsDatepicker
        formControlName="date"
        [bsConfig]="{
          dateInputFormat: 'DD MMMM YYYY',
          showWeekNumbers: false,
         
        }"
        
        (bsValueChange)="onDateChange($event)"
       
      />
    </div>
  </form>
</div> -->



<!-- <mat-slider  [min]="1" [max]="5000">
  <input value="300" matSliderStartThumb>
  <input value="400" matSliderEndThumb>
</mat-slider> -->


<mat-slider [min]="0" [max]="5000" (change)="onSliderChange()">
  <input 
    [(ngModel)]="minValue" 
    matSliderStartThumb 
    (input)="onMinValueChange($event)"
  >
  <input 
    [(ngModel)]="maxValue" 
    matSliderEndThumb 
    (input)="onMaxValueChange($event)"
  >
</mat-slider>



<div class="row">
  <div class="col pt-4">
    <label for="" class="price-label">Min price</label>
    <input type="text" class="form-control" [(ngModel)]="minValue">
  </div>
  <div class="col pt-4">
    <label for="" class="price-label">Max price</label>
    <input type="text" class="form-control" [(ngModel)]="maxValue">
  </div>

</div>
<!-- <p>Selected Min: {{ minValue }}</p>
<p>Selected Max: {{ maxValue }}</p> -->
