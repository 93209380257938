import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CountService } from '../service/count.service';
declare var bootstrap: any;

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrls: ['./new-orders.component.css'],
})
export class NewOrdersComponent {
  mobileData: any = 'orderpage-mobile';
  Display: string = 'ready';
  date: any;
  time: any;
  timeInterval: any;
  userId: string | null = null;
  selectedMenuItem: string = 'Orders';
  currentMenuBar: 'liveOrders' | 'completed' | 'cancel' = 'liveOrders';
  @Output() menuItemClicked = new EventEmitter<string>();
  selectedOrder: any;
  selectedOrderId: string | null = null;
  now: string | undefined;
  acceptData: any[] = [];
  completedOrders: any[] = [];
  orderListData: any;
  liveOrders: any[] = []; 


  constructor(public dialog: MatDialog,private http: HttpClient,public auth: AuthService,public countservice: CountService) {
    document.body.addEventListener('click', function (e) {
      e.stopPropagation();
    });
    // this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.auth.userInfo.subscribe((info: any) => {
      this.userId = info.id;
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.http.post( environment.apiUrl + `/vendororders/order-list`, { vendorId: this.userId }, { responseType: 'json' }).subscribe((data: any) => {
        this.orderListData = data.orders_output;

        // Get the count of filtered orders
        this.liveOrders = data.orders_output.filter(
          (order: any) =>
            order.orderstatus === 'booked' ||
            order.orderstatus === 'in_progress'
        ) .sort((a: any, b: any) => b.ordernumber - a.ordernumber);
        // Filter for completed orders with other statuses
        this.completedOrders = data.orders_output.filter(
          (order: any) => order.orderstatus === 'ready'
        );
      });

    this.updateDateAndTime();
    this.timeInterval = setInterval(() => {
      const now = new Date();
      let hours: number = now.getHours();
      const minutes: string = String(now.getMinutes()).padStart(2, '0');
      const ampm: string = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const hoursString: string = String(hours).padStart(2, '0');
      this.time = `${hoursString}:${minutes} ${ampm}`;
    }, 1000);
  }

  ngOnDestroy() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  updateDateAndTime() {
    const date = new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    this.date = `${day}/${month}/${year}`;
  }

  acceptOrder(order: any): void {
    this.selectedOrderId = null;
    const body = {
      orderStatus: 'in_progress',
      updatedBy: this.userId,
      id: order.id,
    };
    this.http.post(environment.apiUrl + `/vendororders/update`, body, {responseType: 'json',}).subscribe((data: any) => {
        this.acceptData = this.liveOrders.filter(
          (order: any) => order.id === data.orders.id
        );
        const index = this.liveOrders.findIndex(
          (order: any) => order.id === data.orders.id
        );
        if (index !== -1) {
          this.liveOrders[index] = {
            ...this.liveOrders[index],
            ...data.orders,
            cartitems: this.acceptData[0].cartitems,
            firstname: this.acceptData[0].firstname,
            address: this.acceptData[0].address,
            amount: this.acceptData[0].amount,
            slot: this.acceptData[0].slot,
            phone: this.acceptData[0].phone,
            orderstatus: 'in_progress',
          };
        } else {
          this.liveOrders.push(data.orders);
        }
        const inprogressount =  this.liveOrders.filter(
          (order: any) => order.orderstatus === "in_progress"
        );
        
        const bookedcount = this.liveOrders.filter(
          (order: any) => order.orderstatus === "booked"
        );
        
        const bookedCount = bookedcount.length.toString().padStart(2, '0');
        const  inProgressCount = inprogressount.length.toString().padStart(2, '0');
        this.countservice.updateBookedCount(bookedCount);
        this.countservice.updateprogressCount(inProgressCount);
        
      });
    this.currentMenuBar = 'liveOrders';
  }

  inprogress(order: any) {
    this.http.get(environment.apiUrl +`/vendororders/order-get?id=${order.id}&vendorId=${this.userId}`).subscribe((data: any) => {
        this.selectedOrder = data.orders;
      });

    this.Display = 'accept-order';
    this.selectedOrderId = null;
  }

  deliveryOrder(data: any): void {
    const deliveryorder = {
      orderStatus: 'ready',
      updatedBy: this.userId,
      id: data.id,
    };

    this.http.post(environment.apiUrl + `/vendororders/update`, deliveryorder, { responseType: 'json'}) .subscribe((data: any) => {

        const acceptData = this.liveOrders.filter(
          (order: any) => order.id === data.orders.id);

        data.orders.cartitems = acceptData[0].cartitems;
        data.orders.firstname = acceptData[0].firstname;
        data.orders.address = acceptData[0].address;
        data.orders.amount = acceptData[0].amount;
        data.orders.slot = acceptData[0].slot;
        data.orders.phone = acceptData[0].phone;
        data.orders.orderstatus = 'ready';

        this.liveOrders = this.liveOrders.filter(
          (order: any) => order.id !== data.orders.id );

        this.completedOrders.unshift(data.orders);
        this.currentMenuBar = 'completed';
        this.selectedOrderId = null;

        const inprogressCount =  this.liveOrders.filter(
          (order: any) => order.orderstatus === "in_progress");

        const readyCount = this.completedOrders.filter(
          (order: any) => order.orderstatus === "ready");
        
        const readyOrderscount = readyCount.length.toString().padStart(2, '0');
        const inProgresscount = inprogressCount.length.toString().padStart(2, '0');
        this.countservice.updateprogressCount(inProgresscount);
        this.countservice.updatereadyCount(readyOrderscount);
        
      });
  }

  complete(order: any) {
    this.selectedOrder = order;
    this.Display = 'complete-order';
    this.http.get(environment.apiUrl +`/vendororders/order-get?id=${order.id}&vendorId=${this.userId}`).subscribe((data: any) => {
        this.selectedOrder = data.orders;
      });
  }

  selectOrder(order: any) {
    this.http.get( environment.apiUrl +`/vendororders/order-get?id=${order.id}&vendorId=${this.userId}`).subscribe((data: any) => {
        this.selectedOrder = data.orders;
      });

    this.Display = 'booked';
  }

  onClose() {
    this.selectedOrderId = null;
  }

  onMenuClick(menuItem: 'liveOrders' | 'completed' | 'cancel'): void {
    this.menuItemClicked.emit(menuItem);
    this.currentMenuBar = menuItem;
  }

  getSelectedOrderStatus(order: any) {
    this.selectedOrder = order;
  }

  openOffcanvas(): void {
    const offcanvas = document.getElementById('orderId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }

    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }

  hasFlavors(): boolean {
    return this.selectedOrder?.cartitems?.some(
      (item: any) => item?.flavor !== null
    );
  }

  hasSubstitute(): boolean {
    return this.selectedOrder?.cartitems?.some(
      (item: any) => item?.allowsubstitute !== null
    );
  }
}
