<hr class="my-4">
<div class="row px-3  pt-0 financial-content">
  <div class=" col-8  px-0  overflow-auto">
    <table class="financial-table">
      <thead class="py-3 financial-head">
        <tr class="financial-table-head">
          <th class="text-center financial-order-column py-3 px-1 ">Order ID</th>
          <th class="text-center financial-order-column py-3 px-1 ">Product Details</th>
          <th class="text-center financial-amount-column py-3 ps-2 pe-0 ">Amount/Commission</th>
          <th class="text-center financial-action-column py-3 px-1 ">Action</th>
          <th class="text-center financial-status-column py-3 px-1 "> Bill Status</th>
        </tr>
      </thead>
      <tbody class="financial-body mt-3">
        <tr class="py-2 order-row financial-head-solid m-0 mb-3" *ngFor="let order of orderListData">

          <td class="text-center mt-2 product-details default financial-order-column py-0 px-1 product-name mt-3 " style="color: #009CE6;">
            #GP000{{order.ordernumber}}</td>

          <!-- <td
            class="financial-order-column py-0 px-1 product-name d-flex flex-column justify-content-center align-items-center">
            <ul class="list-unstyled p-0 mb-1 product-details default text-center">
              <li>{{order.productDetails}}</li>
            </ul>
            <div class="additional-order title-color text-center">+ 2 more items</div>
          </td> -->

          <td class="financial-order-column py-0 px-1 product-name d-flex flex-column justify-content-center align-items-center">
            <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
              <li>{{order.cartitems[0].producttitle}}</li>
            </ul>
            <div class="additional-order title-color text-center" *ngIf="order.cartitems.length > 1">+
              {{order.cartitems.length - 1}} more items</div>
          </td>
          <td class="financial-amount-column text-center financial-product-details default py-0 px-1 product-name mt-3">

            <span class="currency-symbol"></span>{{order.amount}} / <span class="currency-symbol"></span> {{order.commission}}

          </td>
          <td class="text-center px-1 py-0  financial-action-column  product-name mt-2">
            <div class="d-flex justify-content-evenly">
              <button class="btn completed  px-2 py-2" type="button" data-bs-toggle="offcanvas"
                (click)="financialoffset();complete(order)" data-bs-target="#financialId" aria-controls="financialId">Completed</button>
            </div>
          </td>
          <td class="financial-status-column text-center paid">paid</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class=" text-center  col-4 ps-md-2 px-0 pb-3">
    <div class="card d-flex justify-content-center mx-auto calender-input">
      <input type="text" class="form-control pointer" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker
        [readonly]="isReadOnly" [disabled]="isReadOnly" onkeydown="return false" [(bsValue)]="selectedDate" [bsConfig]="{
        dateInputFormat: 'DD MMMM YYYY',showWeekNumbers: false, }" (bsValueChange)="onDateChange($event)"
        [outsideClick]="false" [isOpen]="isOpen" [dateCustomClasses]="dateCustomClasses" />
      <img src="/assets/images/calendar.svg" alt="calendar" class="me-2 me-lg-0 calendar-image pointer"
        (click)="toggleDesktopCalendar()">
    </div>


    <div class="card mt-3" *ngIf="commissionDetail">
      <div class="card-bady p-3 ">
        <h1 class="me-1 pointer order-complete order-font fw-bold mt-2 text-center mb-0">{{commissionDetail.total_orders.padStart(2, '0')}} </h1>
        <h3 class="order-complete orders-date text-center mb-2">No of orders on {{formatDate(selectedDate)}} </h3>
        <hr class="my-1">
        <div class="d-flex justify-content-evenly my-4">
          <div class=" px-0">
            <div class="text-center financial-product-details revenueDefault"><span class="currency-symbol"></span>{{commissionDetail.total_revenue}}
            </div>
            <div class="title-color text-center mt-2 details-font line-height">Revenue on {{formatDate(selectedDate)}}
            </div>
          </div>
          <div class="vr px-0 "></div>
          <div class=" px-0">
            <div class="text-center financial-product-details revenueDefault"><span class="currency-symbol"></span>{{commissionDetail.total_commission}}
            </div>
            <div class="title-color text-center mt-2 details-font line-height">commission on
              {{formatDate(selectedDate)}}</div>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex justify-content-between my-2">
          <div class="text-start payment-paid details-font">No of orders payment paid</div>
          <div class="text-end payment-paid details-font">{{commissionDetail.orders_payment_paid.padStart(2, '0')}}</div>
        </div>
        <div class="d-flex justify-content-between my-2" *ngIf="!isNoToday(selectedDate)">
          <div class="text-start payment-pending details-font">No of orders payment pending</div>
          <div class="text-end payment-pending details-font">{{commissionDetail.orders_payment_pending.padStart(2, '0')}}</div>
        </div>
        <hr class="my-1">
        <div class="details-font d-flex justify-content-between mt-3 mb-2">
          <div>Total no of orders complete</div>
          <div>{{commissionDetail.total_orders_complete.padStart(2, '0')}} </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
<!-- orer-delivery -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="financialId" aria-labelledby="financialIdLabel"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <div class="d-flex">
      <div class="default title-color  me-4" id="financialIdLabel">Order Id</div>

      <div class="text-center  product-details  name"style="color: #009CE6;">
        #GP000{{selectedOrder?.ordernumber}}</div>

    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="border-style"></div>
  <!--Complete Order -->
  <div class="offcanvas-body py-4  overflow-auto">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>

        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.vendorfirstname	}} </h6>

        <div class="title-color default mt-1 address">{{ selectedOrder?.vendoraddress }}</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class=" d-flex">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.firstname}} </h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.address}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
 
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div>
        <div class=" mb-0 product-details additional-info">Delivery by</div>
        <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
      </div>
    </div>
 
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info">Time Slot</div>
        <div class="  mt-1 default location"> {{selectedOrder?.deliveryscheduledate | date:'MMM dd, E'}} |
          {{selectedOrder?.slot}}</div>
      </div>
    </div>
 
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between ps-4" *ngFor="let cartitems of selectedOrder?.cartitems">
        <ul class="p-0 mb-3 default complete-order-content">
          <li>{{cartitems?.producttitle}}</li>
        </ul>
        <div class="title-color default complete-quantity text-center">X {{cartitems?.quantity}}</div>
        <div class="title-color default text-end amount"><span class="currency-symbol"></span>{{ cartitems?.netamount | number:'1.2-2'}}</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between ps-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details"><span class="currency-symbol"></span>{{ selectedOrder?.amount |
        number:'1.2-2'}}</div>
    </div>
    <div class="border-style"></div>
    <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
  </div>
 


</div>

