
import { Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BsDatepickerConfig, BsDaterangepickerDirective, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { MessageService } from 'primeng/api';

declare var $: any;
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {
  maxDate: Date = new Date(); 
  today: Date = new Date(); 
  isCalendarVisible = false;

  selectedDate: Date = new Date();
  // bsConfig: Partial<BsDatepickerConfig>;
  datePickerForm!: FormGroup;
  isOpen = false;
  dateFormat = 'YYYY/MM/DD';


  dateCustomClasses: DatepickerDateCustomClasses[] | undefined;


  constructor(public dialog: MatDialog,private fb: FormBuilder,private renderer: Renderer2, private el: ElementRef,private route: ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private messageService: MessageService) { 
    // this.maxDate = new Date();
    // this.selectedDate = this.maxDate;
    this.datePickerForm = this.fb.group({
      date:this.maxDate // Set default date
    });

    const now = new Date();
    const twoDaysAhead = new Date();
    twoDaysAhead.setDate(now.getDate());
 
    this.dateCustomClasses = [
      { date: now, classes: ['today'] },
      { date: twoDaysAhead, classes: ['bg-warning'] },
    
    ];
  
  }
  ngOnInit(): void {
      // this.startDate = new Date(); // Set current date as start date
    
    
    }
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    }
    preventInput(event: KeyboardEvent): void {
      event.preventDefault();  // Prevent any input
    }
    visible: boolean = false;

    showConfirm() {
        if (!this.visible) {
            this.messageService.add({ key: 'confirm', sticky: true, severity: 'success', summary: 'Can you send me the report?' });
            this.visible = true;
        }
    }

    onConfirm() {
        this.messageService.clear('confirm');
        this.visible = false;
    }

    onReject() {
        this.messageService.clear('confirm');
        this.visible = false;
    }
    customFormat(value: Date) {
      return value ;
    }
    // bsConfig = {
    //   dateInputFormat: 'DD MMMM YYYY',
    //   showWeekNumbers: false,
    //   customTodayClass: 'today-highlight',
    //   containerClass: 'theme-default',
    //   customDateClass: (date: Date): string => {
    //     if (this.isToday(date)) {
    //       return 'today-highlight';
    //     }
    //     if (date.getTime() === this.selectedDate.getTime()) {
    //       return 'selected-date';
    //     }
    //     return '';
    //   }
    // };




   onDateChange(date: Date): void {
    this.selectedDate = date;
    // this.applyDateStyles();
  }
  
    applyDateStyles(): void {
      const days = document.querySelectorAll('.bs-datepicker-body td span');

      days.forEach((day: any) => {
        const dayValue = parseInt(day.innerText, 10);
  
        // Reset previous styles
        day.classList.remove('selected-date', 'custom-today', 'selected-today');
  
        // Get today's date for comparison
        const today = new Date();
  
        // Check if today
        if (this.isToday(dayValue, today)) {
          if (this.isSelectedDate(dayValue)) {
            day.classList.add('selected-today');  // Today is selected
          } else {
            day.classList.add('custom-today');    // Today but not selected
          }
        }
  
        // Check if selected date
        if (this.isSelectedDate(dayValue)) {
          day.classList.add('selected-date');      // Selected date
        }
      });
    }
  
  
    // isToday(date: Date): boolean {
    //   const today = new Date();
    //   return today.getDate() === date.getDate() &&
    //          today.getMonth() === date.getMonth() &&
    //          today.getFullYear() === date.getFullYear();
    // }
  
    // // Method to check if the day is the selected date
    // isSelected(date: Date): boolean {
    //   return this.selectedDate && this.selectedDate.getTime() === date.getTime();
    // }

    isToday(dayValue: number, today: Date): boolean {
      return today.getDate() === dayValue &&
             today.getMonth() === today.getMonth() &&  // Fixed here
             today.getFullYear() === today.getFullYear(); // Fixed here
    }
  
    isSelectedDate(dayValue: number): boolean {
      return this.selectedDate.getDate() === dayValue &&
             this.selectedDate.getMonth() === this.selectedDate.getMonth() &&
             this.selectedDate.getFullYear() === this.selectedDate.getFullYear();
    }
}




