<div class="d-lg-block d-none mt-4">
  <div class="row">
    <div class="text-start col-6">
      <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('liveOrders')"
        [class.selected]="currentMenuBar === 'liveOrders'"
        [ngClass]="{'selected-title': currentMenuBar === 'liveOrders'}">Live Orders</div>
      <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('completed')"
        [class.selected]="currentMenuBar === 'completed'"
        [ngClass]="{'selected-title': currentMenuBar === 'completed'}">Completed</div>
    </div>
    <div class="d-flex justify-content-end col-6">
      <span class="date default mt-2">{{ date }} - {{ time }}</span>
    </div>
  </div>
</div>

<div class="d-lg-none d-block mt-4 mobile-data">
  <div class="text-start d-flex justify-content-between">
    <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('liveOrders')"
      [class.selected]="currentMenuBar === 'liveOrders'"
      [ngClass]="{'selected-title': currentMenuBar === 'liveOrders'}">Live Orders</div>
    <div class="order-title me-3 px-3 pt-2" (click)="onMenuClick('completed')"
      [class.selected]="currentMenuBar === 'completed'" [ngClass]="{'selected-title': currentMenuBar === 'completed'}">
      Completed</div>
  </div>


</div>
<!-- order-details table -->
<div class="border"></div>
<div class="d-lg-none d-block mt-md-0 mt-3 mobile-data">
  <div class="d-flex justify-content-end ">
    <span class="date default mt-2">{{ date }} - {{ time }}</span>
  </div>
</div>
<div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'liveOrders'">
  <table class="title-table mt-2">
    <thead class="table-head py-3">
      <tr class="title-table-head ">
        <th class="text-center order-column p-3 px-1">Order ID</th>
        <th class="text-center product-column p-3  px-1">Product Details</th>
        <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
        <th class="text-center delivery-column p-3 px-1">Delivery by</th>
        <th class="text-center time-column p-3 px-1">Time Slot</th>
        <th class="text-center amount-column p-3 px-1">Amount</th>
        <th class="text-center action-column p-3 px-1">Action</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of liveOrders">
        <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3"style="color: #009CE6;">
          #GP000{{order.ordernumber}}</td>
        <td class="product-column py-0 px-1 d-flex flex-column justify-content-center align-items-center">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name">
            <li>{{order.cartitems[0].producttitle}}</li>
          </ul>
          <div class="additional-order title-color text-center" *ngIf="order.cartitems.length > 1">+
            {{order.cartitems.length - 1}} more items</div>
        </td>
        <td class="orderBy-coloum py-0 px-1">
          <div class="text-center mb-0  product-details default product-name">{{order.firstname}}</div>
          <div class="text-truncate additional-items mt-2 location text-center">{{order.address}}</div>
        </td>
        <td class="px-md-1 delivery-column py-0 px-1">
          <div *ngIf="!order.deliveryBy" class="d-flex flex-column justify-content-center align-items-center">
            <img src="/assets/images/loading.svg" class="me-3 mt-1 loading-img" alt="loading">
            <div class="order-wait mt-1">Waiting for Delivery Partner</div>
          </div>

          <div *ngIf="order.deliveryBy">
            <div class="text-center mb-0 product-details default product-name">{{order.deliveryBy}}</div>
            <div class="text-center additional-items mt-2 location product-name"> {{order.phone}}</div>
          </div>
        </td>
        <td class="px-md-1 time-column py-0 px-1">
          <div class="text-center mb-0 product-details default">{{ order.deliveryscheduledate | date:'MMM dd, E' }}
          </div>
          <div class="text-center mt-2 additional-items location">{{order.slot}}</div>
        </td>
        <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
          <span class="currency-symbol"></span>{{order.amount}}
        </td>
        <td class="text-center px-1 py-0 mt-1 action-column">
          <div class="d-flex justify-content-evenly">
            <div *ngIf="order.orderstatus === 'booked'" data-bs-toggle="offcanvas" data-bs-target="#orderId"
              aria-controls="orderId">
              <button class="btn new-order  px-2 py-2" type="button" (click)="selectOrder(order);openOffcanvas()">
                New Order
              </button>
              <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1 pointer"
                (click)="selectOrder(order);openOffcanvas()">
            </div>
            <div *ngIf="order.orderstatus === 'in_progress'" data-bs-toggle="offcanvas" data-bs-target="#orderId"
              aria-controls="orderId">
              <button class="btn progress-order  px-2 py-2" type="button" (click)="inprogress(order);openOffcanvas()">
                In Progress </button>
              <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1 pointer"
                (click)="inprogress(order);openOffcanvas()">
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="w-100 overflow-auto" *ngIf="currentMenuBar === 'completed'">
  <table class="title-table mt-2">
    <thead class="table-head py-3">
      <tr class="title-table-head ">
        <th class="text-center order-column p-3 px-1">Order ID</th>
        <th class="text-center product-column p-3  px-1">Product Details</th>
        <th class="text-center orderBy-coloum p-3 px-1">Order by</th>
        <th class="text-center delivery-column p-3 px-1">Delivery by</th>
        <th class="text-center time-column p-3 px-1">Time Slot</th>
        <th class="text-center amount-column p-3 px-1">Amount</th>
        <th class="text-center action-column p-3 px-1">Action</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="py-2 order-row title-table-solid m-0 mb-3" *ngFor="let order of completedOrders">
        <td class="text-center mt-2 product-details default order-column py-0 px-1 product-name mt-3"style="color: #009CE6;">
          #GP000{{order.ordernumber}}</td>
        <td class="product-column py-0 px-1 d-flex flex-column justify-content-center align-items-center">
          <ul class="list-unstyled p-0 mb-1 product-details default text-center product-name ">
            <li>{{order.cartitems[0].producttitle}}</li>
          </ul>
          <div class="additional-order title-color text-center" *ngIf="order.cartitems.length > 1">+
            {{order.cartitems.length - 1}} more items</div>
        </td>
        <td class="orderBy-coloum py-0 px-1">
          <div class="text-center mb-0  product-details default product-name">{{order.firstname}}</div>
          <div class="text-truncate additional-items mt-2 location text-center">{{order.address}}</div>
        </td>
        <td class="px-md-1 delivery-column py-0 px-1">
          <!-- <div class="text-center mb-0 product-details default product-name">{{order.deliveryBy}}</div>
          <div class="text-center additional-items mt-2 location product-name">{{order.phone}}</div> -->
          <div class="text-center mb-0 product-details default product-name">vijay</div>
          <div class="text-center additional-items mt-2 location product-name">+91 9876543210</div>
        </td>
        <td class="px-md-1 time-column py-0 px-1">
          <div class="text-center mb-0 product-details default">{{order.deliveryscheduledate | date:'MMM dd, E' }}</div>
          <div class="text-center mt-2 additional-items location">{{order.slot}}</div>
        </td>
        <td class="amount-column text-center product-details default py-0 px-1 product-name mt-3">
          <span class="currency-symbol"></span>{{order.amount}}
        </td>
        <td class="text-center px-2 py-0 mt-1 action-column">
          <div class="d-flex justify-content-evenly" data-bs-toggle="offcanvas" data-bs-target="#orderId"
            aria-controls="orderId">
            <button class="btn completed p-2" type="button" (click)="complete(order);openOffcanvas()">Completed</button>
            <img src="/assets/images/right-arrow.svg" alt="action" class="action-icon ps-md-2 m-0 ps-1 pointer"
              (click)="complete(order);openOffcanvas()">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- orer-delivery -->

<div class="offcanvas offcanvas-end" tabindex="-1" id="orderId" aria-labelledby="orderIdLabel" data-bs-backdrop="static"
  data-bs-keyboard="false">
  <div class="offcanvas-header">
    <div class="d-flex">
      <h1 class="default title-color  me-4 mb-0" id="orderIdLabel">Order Id</h1>
      <h1 class="text-center  product-details  name mb-0"style="color: #009CE6;">
        #GP000{{selectedOrder?.ordernumber}}</h1>
    </div>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="border-style"></div>
  <!-- New Order -->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'booked'">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">  {{selectedOrder?.vendorfirstname	}} </h6>
        <div class="title-color default mt-1 address">{{ selectedOrder?.vendoraddress }}</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.firstname}} </h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.address}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <img src="/assets/images/loading.svg" class="me-3 mt-1" alt="loading">
      <div class="order-wait mt-1">Waiting for Delivery Partner</div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div>
        <div class=" mb-0 product-details additional-info"> Time Slot </div>
        <div class="  mt-1 default location">{{selectedOrder?.deliveryscheduledate | date:'MMM dd, E'}} |
          {{selectedOrder?.slot}}</div>
      </div>
    </div>
    <div *ngIf="hasFlavors()">
      <div class="border my-3"></div>
      <h2 class="default product-details mb-2">Flavor Instructions</h2>
      <div *ngFor="let cartitems of selectedOrder?.cartitems">
        <div *ngIf="cartitems?.flavor" class="d-flex justify-content-between ps-4 list">
      <div class="order-content d-flex">
          <ul class="p-0 mb-0 default mb-3">
            <li>{{cartitems?.producttitle}}</li>
          </ul>
          <div class="additional-info product-details text-center combo ms-3" *ngIf="cartitems?.type === 'is_combo'">combo</div>
          </div>
          <div class="default cartitem">Extra {{cartitems?.flavor}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="hasSubstitute()">
      <div class="border my-3"></div>
      <h2 class="default product-details  mb-2">Substitutions</h2>
      <div class="d-flex justify-content-between px-4 list" *ngFor="let cartitems of selectedOrder?.cartitems">
        <ul class=" p-0 mb-3 default order-content " *ngIf="cartitems?.allowsubstitute === true">
          <li>{{cartitems?.producttitle}}</li>
        </ul>
      </div>
    </div>
    <div class="border my-3 mb-4"></div>
    <div class="d-flex justify-content-between ps-4 mb-3" *ngFor="let cartitems of selectedOrder?.cartitems">
      <div class="order-content list d-flex">
      <ul class=" p-0 default mb-0" >
        <li>{{cartitems?.producttitle}}</li>
      </ul>
      <div class="additional-info product-details text-center combo ms-2 " *ngIf="cartitems?.type === 'is_combo'" >combo</div>
    </div>
      <div class="title-color default quantity text-center" >X {{cartitems?.quantity}}</div>
      <div class="title-color default text-end amount" > <span class="currency-symbol"></span>
        {{ cartitems?.netamount | number:'1.2-2'}}</div>
    </div>
    <div class="border-style mt-3"></div>
    <div class="d-flex justify-content-between ps-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details text-center "> <span class="currency-symbol"></span>{{ selectedOrder?.amount |
        number:'1.2-2'}}</div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between mb-4 my-4">
      <!-- <button type="submit" class="btn d-block m-auto reject-order ms-2 default title-color" (click)="rejectOrder(selectedOrder, 'reject')"
       data-bs-dismiss="offcanvas" aria-label="Close">Reject Order</button> -->
      <button type="submit" class="btn d-block m-auto accept-order me-2 default w-100"
        (click)="acceptOrder(selectedOrder)" data-bs-dismiss="offcanvas" aria-label="Close">Accept Order</button>
    </div>
  </div>

  <!-- Accept Order - Ready to Delivery Order-->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'accept-order'">
    <div class=" d-flex my-2">
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">
          {{selectedOrder?.vendorfirstname	}} </h6>
        <div class="title-color default mt-1 address">{{ selectedOrder?.vendoraddress }}</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.firstname}}</h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.address}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div>
        <div class=" mb-0 product-details additional-info">DeliveryBy</div>
        <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
      </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div>
        <div class=" mb-0 product-details additional-info"> Time Slot </div>
        <div class="  mt-1 default location">{{selectedOrder?.deliveryscheduledate | date:'MMM dd, E'}} |
          {{selectedOrder?.slot}}</div>
      </div>
    </div>

    <div *ngIf="hasFlavors()">
      <div class="border my-3"></div>
      <h2 class="default product-details mb-2">Flavor Instructions</h2>
      <div *ngFor="let cartitems of selectedOrder?.cartitems">

        <div *ngIf="cartitems?.flavor" class="d-flex justify-content-between ps-4 list mb-3">
      
          <div class="order-content d-flex">
            <ul class="p-0 mb-0 default ">
              <li>{{cartitems?.producttitle}}</li>
            </ul>
            <div class="additional-info product-details text-center combo ms-3" *ngIf="cartitems?.type === 'is_combo'">combo</div>
            </div>

          <div class="default cartitem">Extra {{cartitems?.flavor}}</div>
        </div>
       
      </div>
    </div>

    <div class="border my-4"></div>
    

    <div class="my-4">
      <div class="d-flex justify-content-between ps-4 " *ngFor="let cartitems of selectedOrder?.cartitems">
            <div class="order-content list d-flex mb-3">
          <ul class=" p-0 default mb-0" >
            <li>{{cartitems?.producttitle}}</li>
          </ul>
          <div class="additional-info product-details text-center combo ms-2 " *ngIf="cartitems?.type === 'is_combo'" >combo</div>
        </div>
        <div class="title-color default quantity text-center">X {{cartitems?.quantity}}</div>
        <div class="title-color default text-end amount"> <span class="currency-symbol"></span> {{ cartitems?.netamount | number:'1.2-2'}}</div>
      </div>
    </div>
    <!-- {{ (cartitems?.netamount * cartitems?.quantity)}} -->
    <div class="border-style"></div>
    <div class="d-flex justify-content-between ps-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details text-center "> <span class="currency-symbol"></span>{{ selectedOrder?.amount | number:'1.2-2'}}</div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between mb-4 my-4">
      <button type="submit" class="btn d-block m-auto delivery-order default w-100"
        (click)="deliveryOrder(selectedOrder)" data-bs-dismiss="offcanvas" aria-label="Close">Order ready to
        delivery</button>
    </div>
  </div>
  <!--Complete Order -->
  <div class="offcanvas-body py-4  overflow-auto" *ngIf="Display === 'complete-order'">
    <div class="d-flex my-2">
      <!-- <img src="/assets/images/location-delivery.svg" alt="location"> -->
      <img src="/assets/images/food-delivery.svg" class="me-3 mt-2" alt="delivery" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">  {{selectedOrder?.vendorfirstname	}}  </h6>
        <div class="title-color default mt-1 address">{{ selectedOrder?.vendoraddress }}</div>
      </div>
    </div>
    <div class="vr"></div>
    <div class="d-flex  my-2">
      <img src="/assets/images/location-delivery.svg" class="me-3 mt-2" alt="location" width="25px" height="25px">
      <div>
        <h6 class=" mb-0 text-capitalize product-details default">{{selectedOrder?.firstname}} </h6>
        <div class="title-color default mt-1 address">{{selectedOrder?.address}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="d-flex">
      <img src="/assets/images/delivery-by.svg" class="me-3 mt-2" alt="delivery">
      <div>
        <div class=" mb-0 product-details additional-info">Delivery by</div>
        <div class=" mt-1 location  additional-items">Vijaykumar | +91 90876543210</div>
      </div>
    </div>
    <div class="d-flex my-4">
      <img src="/assets/images/time-delivery.svg" class="me-3 mt-2" alt="time">
      <div class="">
        <div class=" mb-0 product-details additional-info"> Time Slot </div>
        <div class="  mt-1 default location">{{selectedOrder?.deliveryscheduledate | date:'MMM dd, E'}} |
          {{selectedOrder?.slot}}</div>
      </div>
    </div>
    <div class="border my-4"></div>
    <div class="my-4">
      <div class="d-flex justify-content-between ps-4" *ngFor="let cartitems of selectedOrder?.cartitems">
        <ul class="p-0 mb-3 default order-content">
          <li>{{cartitems?.producttitle}}</li>
        </ul>
        <div class="title-color default quantity text-center">X {{cartitems?.quantity}}</div>
        <div class="title-color default text-end amount"> <span class="currency-symbol"></span>{{ cartitems?.netamount |
          number:'1.2-2'}}</div>
      </div>
    </div>
    <div class="border-style"></div>
    <div class="d-flex justify-content-between ps-4 my-4">
      <h6 class=" mb-0 text-capitalize product-details total">Total </h6>
      <div class="default product-details text-center"> <span class="currency-symbol"></span>{{ selectedOrder?.amount |
        number:'1.2-2'}}</div>
    </div>
    <div class="border-style"></div>
    <div class="text-center mb-4 my-4 complete-comment">Order completed</div>
  </div>
</div>