import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, map, tap } from 'rxjs';
import { environment } from '../../environments/environment';
export interface loginResponse {
  locations: any;
  token: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {
   
    // login
 this.accessToken.next(localStorage.getItem('accessToken') || null);
    let accessToken = localStorage.getItem('accessToken')  || null;

    if (accessToken && accessToken !== "undefined") {
      this.userInfo.next(this.decodeToken(accessToken));
    }

    // this.userName.next(localStorage.getItem('userName') || null)
    // if (localStorage.getItem('accessToken')) {
    //    this.userInfo.next(this.decodeToken(this.accessToken.getValue()))
     
    //   }
    this.UserType.next(localStorage.getItem('UserType') || null);
  
  }
// login
searchKey:string =''
public accessToken: BehaviorSubject<any> = new BehaviorSubject(null);
public userName: BehaviorSubject<any>= new BehaviorSubject(null);
userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
UserType: BehaviorSubject<any> = new BehaviorSubject(null);
UserAction: BehaviorSubject<any> = new BehaviorSubject(null);
viewHeader:boolean=true;

isSubmitting = false;

// decodeToken(token: string) { 
//   if(token&&token!=='undefined')
//   return jwtDecode(token);
// }
decodeToken(token: string): any {
  return jwtDecode(token);
}
	
login(credentials: { emailOrphone: string, password: string,rememberme:boolean}) {
  let data:any = credentials;
      if(localStorage.getItem('appToken') && localStorage.getItem('appToken') != null){
        data.appToken = localStorage.getItem('appToken');
      }
  return this.http.post<loginResponse>(environment.apiUrl + '/users/login',data, { responseType: 'json' }).pipe(
    tap((data: any) => {
      if(data.status === "SUCCESS"){
    this.UserAction.next(data.action);
    localStorage.setItem('accessToken', data.token);
    let restoken:any = this.decodeToken(data.token);
    this.userInfo.next(this.decodeToken(data.token));

    localStorage.setItem('userName',restoken?.firstname);
    localStorage.setItem('UserType',"vendor");
    this.UserType.next('vendor');
    this.userName.next(restoken?.firstname);
    this.accessToken.next(data.token);
      }

    }),map((data: any) => {
      return data;
  }));
}


logout() {

localStorage.clear();
this.userName.next(null);

}
}
