<div class="grid-container">
  <div class="grid-item1 d-lg-block d-none">
    <app-sidenav-items (menuItemClicked)="onMenuItemSelected($event)"></app-sidenav-items>
  </div>
  <!-- for Mobile -->
  <div class=" d-lg-none d-block"></div>
  <div class="px-md-4 mt-0 container-lg ">
    <!-- order-details mobile-->
    <div class=" d-lg-none d-block">
      <app-sidenav-items (menuItemClicked)="onMenuItemSelected($event)" [mobileData]="mobileData"></app-sidenav-items>
    </div>
    <div class="row">
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading p-2 h-100">
          <h3 class="title default">New Orders</h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer live-order order-font mt-2 text-truncate">02</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading p-2 h-100">
          <h3 class="title default">Order in Progress</h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer order-complete order-font mt-2 text-truncate">05</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading  p-2 h-100">
          <h3 class="title default">Orders Completed Today</h3>
          <div class="d-flex justify-content-between overflow-auto">
            <h1 class="me-1 pointer order-today order-font mt-2 text-truncate ">10</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-6 mt-3">
        <div class="heading p-2 h-100">
          <h3 class="title default">Total No of Orders </h3>
          <div class="d-flex justify-content-between overflow-auto">
            <!-- <div class="d-flex justify-content-between overflow-auto"matTooltip="Info about the action" aria-label="Button that displays a tooltip when focused or hovered over" > -->
            <h1 class="me-1 pointer total-order order-font  mt-2  text-truncate">100</h1>
            <div class="ms-1 pointer active">
              <img src="assets/images/order-food.svg" class="w-30" alt="order-food">
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>