import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input,  OnInit, Output,     Renderer2, ViewChild,} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
declare var bootstrap: any;
@Component({
  selector: 'app-sidenav-items',
  templateUrl: './sidenav-items.component.html',
  styleUrls: ['./sidenav-items.component.css'],
})
export class SidenavItemsComponent implements OnInit {
  @Input() mobileData: any;
  @Output() menuItemClicked = new EventEmitter<string>();
  activeItem: string | null = null;
  currentSideBar:'Orders' | 'FoodItems' | 'Financial' | 'History' | 'LogOut' = 'Orders';
  hoverState: boolean = false;
  showSignoutPopup = false;
  usertype: any;
  userId: any;
  imageInfo: any;
  imageids: any;
  userprofile: any;
  profileImages: any;

  @ViewChild('profileInput') profileInput: ElementRef | undefined;
  @ViewChild('offcanvasExample', { static: false }) offcanvas:| ElementRef| undefined;
   invalidError = false;

  constructor(private renderer: Renderer2,private router: Router,private http: HttpClient,public auth: AuthService,private messageService: MessageService) {
    this.updateCurrentSideBar(this.router.url);

    // Update the sidebar on navigation events
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentSideBar(this.router.url);
      });

    this.auth.userInfo.subscribe((info: any) => {
      if (info !== undefined && info !== null) {
        this.usertype = info.usertype;
        this.userId = info.id;
      }
    });
  }

  ngOnInit(): void {
    this.http.get(environment.apiUrl + `/users/get?id=${this.userId}`).subscribe ({
      next: (res: any) => {
        if (res.status === 'SUCCESS') {
          if (res.users.status === 1) {
            this.invalidError = false;
            const imageInfoArray = JSON.parse(res.users.imageinfo);
            if (imageInfoArray && Array.isArray(imageInfoArray) && imageInfoArray.length > 0) {
              const imageId = imageInfoArray[0].imageid;
            }
            this.uploadimages(res.users.id);
          } else if (res.users.status === 2) {
            // this.invalidToast = true; 
            // if (!this.invalidError ) {
              this.invalidError = true; // Prevent duplicate toasts
              this.showToastError();
              this.clearUserData();
              this.router.navigate(['/login']);
            // }
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
     
        
      })
  }


  @HostListener('window:resize', [])
  onResize(): void {
    // const width = window.innerWidth;
  
    // // // Close modals on resize
    // // const modals = document.querySelectorAll('.modal');
    // // modals.forEach((modal) => {
    // //   const bsModal = bootstrap.Modal.getInstance(modal);
    // //   if (bsModal) {
    // //     bsModal.hide();
    // //   }
    // // });
  
    // // Show the appropriate modal based on window width
    // if (width <= 768) {
    //   this.logout(); // For smaller screens
    // } 
  }
  
  
private clearUserData(): void {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('UserType');
  localStorage.removeItem('userName');
  localStorage.removeItem('userId');
}
  updateCurrentSideBar(url: string): void {
    if (url.includes('new-orders')) {
      this.currentSideBar = 'Orders';
    } else if (url.includes('food-items-page')) {
      this.currentSideBar = 'FoodItems';
    } else if (url.includes('financial-page')) {
      this.currentSideBar = 'Financial';
    } else if (url.includes('history-page')) {
      this.currentSideBar = 'History';
    } else {
      this.currentSideBar = 'Orders';
    }
  }

  onMenuItemClick(
    menuItem: 'Orders' | 'FoodItems' | 'Financial' | 'History'
  ): void {
    this.menuItemClicked.emit(menuItem);
    this.currentSideBar = menuItem;
    // const offcanvasElement = document.querySelector(
    //   '.offcanvas.offcanvas-start.show'
    // );
    // if (offcanvasElement) {
    //   this.renderer.removeClass(offcanvasElement, 'show');
    // }
    // const backdropElement = document.querySelector(
    //   '.offcanvas-backdrop.fade.show'
    // );
    // if (backdropElement) {
    //   this.renderer.removeClass(backdropElement, 'offcanvas-backdrop');
    //   this.renderer.removeClass(backdropElement, 'show');
    // }
  }
  // closeOffcanvas(): void {
  //   const offcanvasElement = document.querySelector(
  //     '.offcanvas.offcanvas-start.show'
  //   );
  //   if (offcanvasElement) {
  //     this.renderer.removeClass(offcanvasElement, 'offcanvas');
  //     this.renderer.removeClass(offcanvasElement, 'offcanvas-start');
  //     this.renderer.removeClass(offcanvasElement, 'show');
  //   }
  // }
  getImageSrc(
    item: 'Orders' | 'food-item' | 'financial' | 'history',
    isActive: boolean,
    isHover: boolean
  ): string {
    // Return the appropriate image src based on the active state

    const baseSrc = 'assets/images/';
    const suffix = isActive || isHover ? '-color.svg' : '.svg';
    return `${baseSrc}${item.toLowerCase().replace(/ /g, '-')}${suffix}`;
  }
  toggleSignoutPopup() {
  
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('UserType');
    this.router.navigate(['/login']);
  }



  uploadimages(id: any) {
    this.http.get( environment.apiUrl + `/uploads/list-by-datafor?dataFor=vendors&dataForId=${id}`).subscribe((datas: any) => {
        if (datas.status === 'SUCCESS') {
          datas.uploads.forEach((upload: any, index: number) => {
            this.http.get(`${environment.apiUrl}/uploads/get?id=${upload.id}`).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                  if (res.uploads && res.uploads.base64data) {
                    const base64Data = res.uploads.base64data.trim();
                    const prefix = 'data:image/jpeg;base64,';
                    this.userprofile = base64Data.startsWith(prefix)
                      ? base64Data
                      : prefix + base64Data;
                  } else {
                    console.error(
                      'No base64 data found in the image response.'
                    );
                  }
                }
              });
          });
        }
      });
  }
  triggerUpload() {
    this.renderer.selectRootElement(this.profileInput?.nativeElement).click();
  }

  handleLicenceChange(event: any): void {
    const file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        const maxWidth = 2400;
        const maxHeight = 1800;
        const thumbnailSize = 350;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);

          const compressedBase64String = canvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed

          // Create thumbnail
          const thumbnailCanvas = document.createElement('canvas');
          const thumbnailCtx: CanvasRenderingContext2D | null =
            thumbnailCanvas.getContext('2d');

          thumbnailCanvas.width = thumbnailSize;
          thumbnailCanvas.height = thumbnailSize;

          if (thumbnailCtx) {
            thumbnailCtx.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);

            const thumbnailBase64String = thumbnailCanvas.toDataURL(
              'image/jpeg',
              0.7
            ); // Adjust quality as needed

            const request = {
              dataType: 'image',
              dataFor: 'vendors',
              dataForId: '',
              base64Data: compressedBase64String,
              thumbnail: thumbnailBase64String,
              createdBy: this.userId,
            };
            this.http.post(environment.apiUrl + `/uploads/create`, request, { responseType: 'json',}).subscribe((data: any) => {
                if (data.status === 'SUCCESS') {
                  let info: any[] = [];
                  const ids = {
                    imageid: data.uploads.id,
                    position: 0,
                    visible: 'true',
                  };
                  info.push(ids);
                  const formattedImageInfo = JSON.stringify(info);
                  const request = {
                    id: this.userId,
                    updatedBy: this.userId,
                    userType: this.usertype,
                    imageids: [data.uploads.id],
                    imageInfo: formattedImageInfo,
                  };
                  this.http .post(environment.apiUrl + `/users/vendor-profile-update`,
                      request, { responseType: 'json' }).subscribe((res: any) => {
                      if (res.status === 'SUCCESS') {
                        const imageInfoArray = JSON.parse(res.users.imageinfo);
                        // Access the imageid
                        if (
                          imageInfoArray !== null &&
                          imageInfoArray !== undefined &&
                          imageInfoArray !== ''
                        ) {
                          const imageId = imageInfoArray[0].imageid;

                        }
                        this.uploadimages(res.users.id);
                      }
                    });
                }
              });
          }
        }
      };

      img.src = base64String;
    };

    reader.readAsDataURL(file);
  }


  showToastError() {
    this.messageService.add({ severity: 'error', summary: 'Account Disabled', detail: 'Your account has been disabled. Please contact the Administrator for assistance.',sticky: true });
  }

  sidenavOffset(): void {
    const offcanvas = document.getElementById('sidenavId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }

    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }

  logout(): void {
    const modalElement = document.getElementById('logoutdrop');
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }

    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.modal-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
  logout_web(): void {
    const modalElement = document.getElementById('staticBackdrop');
    if (modalElement) {
    const modal = new bootstrap.Modal(modalElement);
    modal.show();
    }
    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.modal-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
}
