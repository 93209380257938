import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { DatefotmatService } from '../service/datefotmat.service';
declare var $: any;
declare var bootstrap: any;
 
@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.css'],
})
export class FinancialComponent implements OnInit {
  selectedMenuItem: string = '';
  date: Date = new Date();
  datefotmat: any = '';
  maxDate: any;
  isCalendarVisible = false;
  selectedDate: Date = new Date();
  en: any;
  isOpen = false;
  isOpenMobile = false;
  userId: string | null =null;
  commissionDetail:any;
  orderListData :any[]=[]
  isReadOnly: boolean = true;
  dateCustomClasses: DatepickerDateCustomClasses[] | undefined;
  selectedOrder: any;



  constructor(
    private dateService: DatefotmatService,
    private renderer: Renderer2,private http: HttpClient, public auth: AuthService,
  ) {
    this.maxDate = new Date();
    this.updateDateCustomClasses();
    this.auth.userInfo.subscribe((info:any) =>{
      this.userId = info.id
    })
  }
 
  ngOnInit(): void {
    window.scroll(0, 0);
    this.datefotmat = this.formatDate(this.date);
    const startTimestamp = this.normalizeToLocalDate(this.date);
    const endTimestamp = this.normalizeToLocalDate(this.date, true);
    this.http.post(environment.apiUrl + `/vendororders/financial-calculation`,{ vendorId: this.userId,orderStatus: "ready",startDate:startTimestamp,  endDate:endTimestamp},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
       this.commissionDetail = data.orders_output;
       
      }})
  }
  normalizeToLocalDate = (date: Date | null, endOfDay: boolean = false): number | null => {
    if (!date) return null; // Handle null or undefined date
    const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (endOfDay) {
      normalizedDate.setHours(23, 59, 59, 999); // Set to 11:59:59.999 PM
    }
    return normalizedDate.getTime();
  };
  onMenuItemSelected(menuItem: string): void {
    this.selectedMenuItem = menuItem;
  }
  ngAfterViewInit() {
    this.setCustomDayNames();
  }
  toggleDesktopCalendar() {
    this.isOpen = !this.isOpen; // Toggle desktop datepicker
  }
 
  toggleMobileCalendar() {
    this.isOpenMobile = !this.isOpenMobile; // Toggle mobile datepicker
  }
  setCustomDayNames() {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayCells = document.querySelectorAll(
      '.p-datepicker table > thead > tr > th'
    );
 
    if (dayCells.length > 0) {
      dayCells.forEach((cell, index) => {
        // Set the custom day names
        cell.textContent = dayNames[index];
        cell.classList.add('custom-day-names'); // Add a custom class for styling
      });
    }
  }
  onClose() {
    // this.Display = 'new-order';
  }
  clickCallBack(event: Event) {}
 
  isNoToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() !== today.getDate() ||
      date.getMonth() !== today.getMonth() ||
      date.getFullYear() !== today.getFullYear()
    );
  }
 
  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
 
  toggleCalendar() {
    this.isCalendarVisible = !this.isCalendarVisible;
  }
 
  display() {
    const calendarElement = document.querySelector('.p-datepicker-calendar-container');
    if (calendarElement) {
      this.renderer.addClass(calendarElement, 'visible');
      this.renderer.removeClass(calendarElement, 'hidden');
    }
    this.isCalendarVisible = false;
  }
 
  displayUp() {
    const calendarElement = document.querySelector('.p-datepicker-calendar-container');
    if (calendarElement) {
      this.renderer.addClass(calendarElement, 'hidden');
      this.renderer.removeClass(calendarElement, 'visible');
      this.isCalendarVisible = true;
    }
  }
  onDateSelect(): void {
    this.datefotmat = this.formatDate(this.date);
    this.isCalendarVisible = false; // Hide the calendar after selecting a date
  }
  onDateChange(date: Date): void {
    this.selectedDate = date;
    this.updateDateCustomClasses();


    const startTimestamp = this.normalizeToLocalDate(date);
    const endTimestamp = this.normalizeToLocalDate(date, true);

     this.http.post(environment.apiUrl + `/vendororders/order-list`,{ vendorId: this.userId,orderStatus: "ready",startDate:startTimestamp,  endDate:endTimestamp},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
        this.orderListData = data.orders_output.sort((a: any, b: any) => b.ordernumber - a.ordernumber);

       }
    })
    this.http.post(environment.apiUrl + `/vendororders/financial-calculation`,{ vendorId: this.userId,orderStatus: "ready",startDate:startTimestamp,  endDate:endTimestamp},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
       this.commissionDetail = data.orders_output;
       
      }})
     
  }
  updateDateCustomClasses() {
    const now = new Date();
 
    // Check if today is selected
    const isTodaySelected =
      this.selectedDate?.getDate() === now.getDate() &&
      this.selectedDate?.getMonth() === now.getMonth() &&
      this.selectedDate?.getFullYear() === now.getFullYear();
 
    this.dateCustomClasses = [
      {
        date: now,
        classes: isTodaySelected ? ['today', 'selected'] : ['bg-warning'],
      },
    ];
  }



  complete(order: any) {
    this.selectedOrder = order;
    // this.Display = 'complete-order';
    this.http.get(environment.apiUrl +`/vendororders/order-get?id=${order.id}&vendorId=${this.userId}`).subscribe((data: any) => {
        this.selectedOrder = data.orders;
      });
  }
  financialoffset(): void {
    const offcanvas = document.getElementById('financialId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }
 
    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
}
 
 